import styled from "styled-components"

export const UploadChunkFilesStyle = styled.div`
  position: fixed;
  bottom: -5px;
  right: 15px;
  z-index: 1001;
  background: #eff2ff;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  .close-box {
    .close-icon {
      width: 0px;
      opacity: 0;
      transition: all 1s;
    }
    .is-time-remaining {
      max-width: 180px;
    }
    .not-time-remaining {
      max-width: 280px;
    }
    :hover {
      .close-icon {
        width: 24px !important;
        opacity: 1 !important;
      }
      /* .is-time-remaining {
        width: 160px;
      } */
      .not-time-remaining {
        max-width: 230px;
      }
    }
  }
  .ex-button {
    padding: 8px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    margin-right: 8px;
  }
  .ant-collapse-extra {
    height: 28px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-collapse {
    border: none;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px,
      rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
  }
  .ipload-chunk-item {
    max-height: 240px;
    width: 380px;
    overflow: hidden auto;
    margin: -16px -16px -16px -16px;
    padding: 8px 8px 4px 8px;
  }
`
