import { CloseCircleFilled, ReloadOutlined } from "@ant-design/icons"
import { Progress, Tooltip } from "antd"
import { useDispatch } from "react-redux"
import SvgIcon from "src/components/SvgIcon"

const UploadChunkItem = ({ data }) => {
  const dispatch = useDispatch()

  const setIconFile = type => {
    switch (type) {
      case "pdf":
        return "file-pdf"
      case "doc":
      case "docx":
        return "file-doc"
      case "jpg":
      case "jpeg":
      case "gif":
      case "png":
      case "svg":
        return "small-image-red"
      default:
        return "iconPdf"
    }
  }
  return (
    <div className="d-flex-sb mb-6  mt-6 close-box" style={{ gap: "8px" }}>
      <div className="d-flex-start">
        <SvgIcon
          name={setIconFile(data?.type?.split("/")[1])}
          className="mr-8"
        />{" "}
        <div className={"d-flex-start no-wrap"}>
          <div
            className={`max-line1 ${"is-time-remaining"}`}
            style={{
              display: "block",
            }}
          >
            {data?.name}
          </div>

          <div className="ml-4">- Còn 0 giây </div>
        </div>
      </div>
      <div
        className="d-flex-end "
        style={{
          background: "#fff",
          marginRight: 8,
        }}
      >
        <div className="d-flex-center mr-8">
          <Progress
            strokeLinecap="butt"
            type="circle"
            status={"exception"}
            percent={100}
            size={30}
            showInfo={true}
          />
        </div>
        <div className="d-flex-end" style={{ gap: "8px" }}>
          <div className=" close-icon d-flex-center " onClick={() => {}}>
            <Tooltip title="Upload lại" mouseEnterDelay={1}>
              <ReloadOutlined
                className="pointer"
                style={{
                  color: "#0d99ff",
                  fontSize: "26px",
                  cursorL: "pointer",
                }}
              />
            </Tooltip>
          </div>

          <div className=" close-icon d-flex-center " onClick={() => {}}>
            <Tooltip title="Xóa" mouseEnterDelay={1}>
              <CloseCircleFilled
                className="pointer"
                style={{
                  color: "#e50500",
                  fontSize: "26px",
                  cursorL: "pointer",
                }}
              />
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  )
}

export default UploadChunkItem
