import ROUTER from "src/router"
export const routeNav = MS =>
  [
    {
      // Number: "1.012128",
      name: "Đơn đăng ký sáng chế",
      Router: ROUTER.TO_KHAI_DANG_KY_SANG_CHE,
      Prefix: "1",
    },
    {
      // Number: "1.012128",
      name: "Đơn đăng ký GPHI",
      Router: ROUTER.TO_KHAI_DANG_KY_GPHI,
      Prefix: "2",
    },
    {
      // Number: "1.012131",
      name: "Đơn đăng ký kiểu dáng công nghiệp",
      Router: ROUTER.TO_KHAI_DANG_KY_KIEU_DANG_CONG_NGHIEP,
      Prefix: "3",
    },
    {
      // Number: "1.012132",
      name: "Đơn đăng ký nhãn hiệu",
      Router: ROUTER.TO_KHAI_DANG_KY_NHAN_HIEU,
      Prefix: "4",
    },
    {
      // Number: "",
      name: "Thủ tục xử lý Đơn Madrid có chỉ định Việt Nam",
      Router: "",
      Prefix: "5",
    },
    {
      // Number: "1.012133",
      name: "Đơn đăng ký chỉ dẫn địa lý",
      Router: ROUTER.TO_KHAI_DANG_KY_CHI_DAN_DIA_LY,
      Prefix: "6",
    },
    {
      // Number: "1.012130",
      name: "Đơn đăng ký thiết kế bố trí",
      Router: ROUTER.TO_KHAI_DANG_KY_THIET_KE_BO_TRI_MACH_TICH_HOP_BAN_DAN,
      Prefix: "7",
    },
    {
      // Number: "",
      name: "Thủ tục xử lý Đơn La Hay có chỉ định Việt Nam",
      Router: "",
      Prefix: "8",
    },
    {
      // Number: "",
      name: "Đơn đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: ROUTER.TO_KHAI_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
      Prefix: "A",
    },
    {
      // Number: "",
      name: "Đơn huỷ bỏ hiệu lực đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "AC",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu giới hạn, hạn chế danh mục sản phẩm, dịch vụ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt",
      Router: "",
      Prefix: "AL",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu sửa đổi tên, địa chỉ, đại diện SHCN đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "AM",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu gia hạn đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "AR",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu mở rộng lãnh thổ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "AS",
    },
    {
      // Number: "",
      name: "Đơn chuyển nhượng đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "AT",
    },
    {
      // Number: "",
      name: "Đơn đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: ROUTER.TO_KHAI_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
      Prefix: "B",
    },
    {
      // Number: "",
      name: "Đơn hủy bỏ hiệu lực đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "BC",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu giới hạn, hạn chế danh mục sản phẩm, dịch vụ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "BL",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu sửa đổi tên, địa chỉ, đại diện SHCN đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "BM",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu gia hạn đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "BR",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu mở rộng lãnh thổ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "BS",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cấp bản sao tài liệu thông tin sở hữu công nghiệp",
      Router: "",
      Prefix: "BSTL",
    },
    {
      // Number: "",
      name: "Đơn chuyển nhượng đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "BT",
    },
    {
      // Number: "1.011913",
      name: "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Sáng chế",
      Router: ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN,
      Prefix: "CB1",
    },
    {
      // Number: "1.011913",
      name: "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Giải pháp hữu ích",
      Router: ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN,
      Prefix: "CB2",
    },
    {
      // Number: "1.011913",
      name: "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Kiểu dáng công nghiệp",
      Router: ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN,
      Prefix: "CB3",
    },
    {
      // Number: "1.011913",
      name: "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Nhãn hiệu",
      Router: ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN,
      Prefix: "CB4",
    },
    {
      // Number: "1.011913",
      name: "Đơn đăng ký hợp đồng chuyển giao quyền sở hữu đối tượng SHCN - Thiết kế bố trí",
      Router: ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN,
      Prefix: "CB7",
    },
    {
      // Number: "1.011922",
      name: "Đơn đăng ký cấp chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_CAP_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
      Prefix: "CCĐD",
    },
    {
      // Number: "1.011931",
      name: "Đơn yêu cầu cấp Thẻ giám định viên sở hữu công nghiệp",
      Router: ROUTER.TO_KHAI_YEU_CAU_CAP_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP,
      Prefix: "CCGĐ",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cung cấp thông tin SHCN - Sáng chế",
      Router: "",
      Prefix: "CCTT1",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cung cấp thông tin SHCN - GPHI",
      Router: "",
      Prefix: "CCTT2",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cung cấp thông tin SHCN - Kiểu dáng CN",
      Router: "",
      Prefix: "CCTT3",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cung cấp thông tin SHCN - Nhãn hiệu",
      Router: "",
      Prefix: "CCTT4",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cung cấp thông tin SHCN - Chỉ dẫn địa lý",
      Router: "",
      Prefix: "CCTT6",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cung cấp thông tin SHCN - Thiết kế bố trí",
      Router: "",
      Prefix: "CCTT7",
    },
    {
      // Number: "1.011903",
      name: "Chuyển giao đơn - Sáng chế",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "CĐ1",
    },
    {
      // Number: "1.011903",
      name: "Chuyển giao đơn - Giải pháp hữu ích",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "CĐ2",
    },
    {
      // Number: "1.011903",
      name: "Chuyển giao đơn - Kiểu dáng công nghiệp",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "CĐ3",
    },
    {
      // Number: "1.011903",
      name: "Chuyển giao đơn - Nhãn hiệu",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "CĐ4",
    },
    {
      // Number: "1.011903",
      name: "Chuyển giao đơn - Thiết kế bố trí",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "CĐ7",
    },
    {
      // Number: "1.011923",
      name: "Đơn đăng ký cấp lại chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_CAP_LAI_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
      Prefix: "CLCC",
    },
    {
      // Number: "1.011932",
      name: "Đơn yêu cầu cấp lại Thẻ giám định viên sở hữu công nghiệp",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_CAP_LAI_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP,
      Prefix: "CLGĐ",
    },
    {
      // Number: "1.011907",
      name: "Đơn yêu cầu duy trì hiệu lực VBBH - Sáng chế",
      Router: ROUTER.TO_KHAI_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO,
      Prefix: "DT1",
    },
    {
      // Number: "1.011907",
      name: "Đơn yêu cầu duy trì hiệu lực VBBH  -  Giải pháp hữu ích",
      Router: ROUTER.TO_KHAI_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO,
      Prefix: "DT2",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Sáng chế",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐC1",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Giải pháp hữu ích",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐC2",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Kiểu dáng công nghiệp",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐC3",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Nhãn hiệu",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐC4",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Chỉ Dẫn Địa Lý",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐC6",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu chấm dứt hiệu lực VBBH (do chủ VBBH tuyên bố từ bỏ quyền) - Thiết kế bố trí",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐC7",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "ĐCLX1",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "ĐCLX2",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "ĐCLX3",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "ĐCLX4",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "ĐCLX7",
    },
    {
      // Number: "1.011917",
      name: "Đơn yêu cầu chấm dứt hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - LI XĂNG BẮT BUỘC",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_CHUYEN_NHUONG_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "ĐCLXBB",
    },
    {
      // Number: "1.011909",
      name: "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐN1",
    },
    {
      // Number: "1.011909",
      name: "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Giải pháp hữu ích",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐN2",
    },
    {
      // Number: "1.011909",
      name: "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Kiểu dáng công nghiệp",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐN3",
    },
    {
      // Number: "1.011909",
      name: "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Nhãn hiệu",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐN4",
    },
    {
      // Number: "1.011909",
      name: "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Nhãn hiệu quốc tế",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐN5",
    },
    {
      // Number: "1.011909",
      name: "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Chỉ dẫn địa lý",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐN6",
    },
    {
      // Number: "1.011909",
      name: "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - Thiết kế bố trí",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐN7",
    },
    {
      // Number: "1.011909",
      name: "Đơn yêu cầu chấm dứt, huỷ bỏ hiệu lực VBBH (do bên thứ ba yêu cầu) - LAHAY",
      Router: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
      Prefix: "ĐN8",
    },
    {
      // Number: "1.011908",
      name: "Đơn yêu cầu gia hạn hiệu lực VBBH - Kiểu dáng công nghiệp",
      Router: ROUTER.TO_KHAI_GIA_HAN_HIEU_LUC_VAN_BANG_BAO_HO,
      Prefix: "GH3",
    },
    {
      // Number: "1.011908",
      name: "Đơn yêu cầu gia hạn hiệu lực VBBH - Nhãn hiệu",
      Router: ROUTER.TO_KHAI_GIA_HAN_HIEU_LUC_VAN_BANG_BAO_HO,
      Prefix: "GH4",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu gia hạn hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "GHLX1",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu gia hạn hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "GHLX2",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu gia hạn hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "GHLX3",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu gia hạn hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "GHLX4",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu gia hạn hiệu lực hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "GHLX7",
    },
    {
      // Number: "",
      name: "Ghi nhận đăng bạ - Sáng chế",
      Router: "",
      Prefix: "GNĐB1",
    },
    {
      // Number: "",
      name: "Ghi nhận đăng bạ - Giải pháp hữu ích",
      Router: "",
      Prefix: "GNĐB2",
    },
    {
      // Number: "",
      name: "Ghi nhận đăng bạ - Kiểu dáng công nghiệp",
      Router: "",
      Prefix: "GNĐB3",
    },
    {
      // Number: "",
      name: "Ghi nhận đăng bạ - Nhãn hiệu",
      Router: "",
      Prefix: "GNĐB4",
    },
    {
      // Number: "",
      name: "Ghi nhận đăng bạ - Chỉ dẫn địa lý",
      Router: "",
      Prefix: "GNĐB6",
    },
    {
      // Number: "",
      name: "Ghi nhận đăng bạ - Thiết kế bố trí",
      Router: "",
      Prefix: "GNĐB7",
    },
    {
      // Number: "",
      name: "Ghi nhận gia hạn - Kiểu dáng công nghiệp",
      Router: "",
      Prefix: "GNGH3",
    },
    {
      // Number: "",
      name: "Ghi nhận gia hạn - Nhãn hiệu",
      Router: "",
      Prefix: "GNGH4",
    },
    {
      // Number: "",
      name: "Đơn đăng ký quốc tế KDCN có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "H",
    },
    {
      // Number: "1.021135",
      name: "Đơn khiếu nại - Sáng chế",
      Router:
        ROUTER.TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP,
      Prefix: "KN1",
    },
    {
      // Number: "1.021135",
      name: "Đơn khiếu nại - Giải pháp hữu ích",
      Router:
        ROUTER.TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP,
      Prefix: "KN2",
    },
    {
      // Number: "1.021135",
      name: "Đơn khiếu nại - Kiểu dáng công nghiệp",
      Router:
        ROUTER.TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP,
      Prefix: "KN3",
    },
    {
      // Number: "1.021135",
      name: "Đơn khiếu nại - Nhãn hiệu",
      Router:
        ROUTER.TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP,
      Prefix: "KN4",
    },
    {
      // Number: "1.021135",
      name: "Đơn khiếu nại - Nhãn hiệu quốc tế",
      Router:
        ROUTER.TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP,
      Prefix: "KN5",
    },
    {
      // Number: "1.021135",
      name: "Đơn khiếu nại - Chỉ dẫn địa lý",
      Router:
        ROUTER.TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP,
      Prefix: "KN6",
    },
    {
      // Number: "1.021135",
      name: "Đơn khiếu nại - Thiết kế bố trí",
      Router:
        ROUTER.TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP,
      Prefix: "KN7",
    },
    {
      // Number: "1.021135",
      name: "Đơn khiếu nại - LAHAY",
      Router:
        ROUTER.TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP,
      Prefix: "KN8",
    },
    {
      // Number: "1.011930",
      name: "Đăng ký dự kiểm tra nghiệp vụ giám định sở hữu công nghiệp",
      Router:
        ROUTER.TO_KHAI_THU_TUC_DANG_KY_DU_KIEM_TRA_NGHIEP_VU_GIAM_DINH_SO_HUU_CONG_NGHIEP,
      Prefix: "KTGĐ",
    },
    {
      // Number: "1.011921",
      name: "Đăng ký dự kiểm tra nghiệp vụ đại diện sở hữu công nghiệp",
      Router:
        ROUTER.TO_KHAI_DANG_KY_DU_KIEM_TRA_NGHIEP_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
      Prefix: "KTNV",
    },
    {
      // Number: "1.011914",
      name: "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
      Router:
        ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN,
      Prefix: "LX1",
    },
    {
      // Number: "1.011914",
      name: "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
      Router:
        ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN,
      Prefix: "LX2",
    },
    {
      // Number: "1.011914",
      name: "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
      Router:
        ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN,
      Prefix: "LX3",
    },
    {
      // Number: "1.011914",
      name: "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
      Router:
        ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN,
      Prefix: "LX4",
    },
    {
      // Number: "1.011914",
      name: "Đơn đăng ký hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí",
      Router:
        ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN,
      Prefix: "LX7",
    },
    {
      // Number: "1.011916",
      name: "Đơn yêu cầu bắt buộc chuyển giao quyền sử dụng sáng chế",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_BAT_BUOC_CHUYEN_GIAO_QUYEN_SU_DUNG_SANG_CHE,
      Prefix: "LXBB",
    },
    {
      // Number: "",
      name: "Đơn đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: ROUTER.TO_KHAI_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
      Prefix: "P",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp phó bản VBBH - Sáng chế",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "PB1",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp phó bản VBBH - Giải pháp hữu ích",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "PB2",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp phó bản VBBH - Kiểu dáng công nghiệp",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "PB3",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp phó bản VBBH - Nhãn hiệu",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "PB4",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp phó bản VBBH - Thiết kế bố trí",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "PB7",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
      Router: "",
      Prefix: "PBLX1",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
      Router: "",
      Prefix: "PBLX2",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
      Router: "",
      Prefix: "PBLX3",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
      Router: "",
      Prefix: "PBLX4",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp phó bản hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí",
      Router: "",
      Prefix: "PBLX7",
    },
    {
      // Number: "",
      name: "Đơn hủy bỏ hiệu lực đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "PC",
    },
    {
      // Number: "",
      name: "Đơn đăng ký sáng chế theo Hiệp ước PCT có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "PCT/VN",
    },
    {
      // Number: "1.012134",
      name: "Đơn phản đối - Sáng chế",
      Router: "",
      Prefix: "PĐ1",
    },
    {
      // Number: "1.012134",
      name: "Đơn phản đối - Giải pháp hữu ích",
      Router: "",
      Prefix: "PĐ2",
    },
    {
      // Number: "1.012134",
      name: "Đơn phản đối - Kiểu dáng công nghiệp",
      Router: "",
      Prefix: "PĐ3",
    },
    {
      // Number: "1.012134",
      name: "Đơn phản đối - Nhãn hiệu",
      Router: "",
      Prefix: "PĐ4",
    },
    {
      // Number: "1.012134",
      name: "Đơn phản đối - Nhãn hiệu quốc tế",
      Router: "",
      Prefix: "PĐ5",
    },
    {
      // Number: "1.012134",
      name: "Đơn phản đối - Chỉ dẫn địa lý",
      Router: "",
      Prefix: "PĐ6",
    },
    {
      // Number: "1.012134",
      name: "Đơn phản đối - Thiết kế bố trí",
      Router: "",
      Prefix: "PĐ7",
    },
    {
      // Number: "1.012134",
      name: "Đơn phản đối - LAHAY",
      Router: "",
      Prefix: "PĐ8",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu giới hạn, hạn chế danh mục sản phẩm, dịch vụ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt",
      Router: "",
      Prefix: "PL",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu sửa đổi tên, địa chỉ, đại diện SHCN đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "PM",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu gia hạn đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "PR",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu mở rộng lãnh thổ đăng ký Quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "PS",
    },
    {
      // Number: "",
      name: "Đơn chuyển nhượng đăng ký quốc tế nhãn hiệu có nguồn gốc Việt Nam",
      Router: "",
      Prefix: "PT",
    },
    {
      // Number: "",
      name: "Đăng ký phúc tra nghiệp vụ đại diện sở hữu công nghiệp",
      Router: "",
      Prefix: "PTĐD",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp lại VBBH - Sáng chế",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "RB1",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp lại VBBH - Giải pháp hữu ích",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "RB2",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp lại VBBH - Kiểu dáng công nghiệp",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "RB3",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp lại VBBH - Nhãn hiệu",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "RB4",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp lại VBBH - Chỉ Dẫn Địa Lý",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "RB6",
    },
    {
      // Number: "1.011912",
      name: "Đơn yêu cầu cấp lại VBBH - Thiết kế bố trí",
      Router: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
      Prefix: "RB7",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
      Router: "",
      Prefix: "RBLX1",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
      Router: "",
      Prefix: "RBLX2",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
      Router: "",
      Prefix: "RBLX3",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu",
      Router: "",
      Prefix: "RBLX4",
    },
    {
      // Number: "1.011918",
      name: "Đơn yêu cầu cấp lại hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế",
      Router: "",
      Prefix: "RBLX7",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cấp lại phó bản VBBH - Sáng chế",
      Router: "",
      Prefix: "RBPB1",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cấp lại phó bản VBBH - Giải pháp hữu ích",
      Router: "",
      Prefix: "RBPB2",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cấp lại phó bản VBBH - Kiểu dáng công nghiệp",
      Router: "",
      Prefix: "RBPB3",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cấp lại phó bản VBBH - Nhãn hiệu",
      Router: "",
      Prefix: "RBPB4",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cấp lại phó bản VBBH - Thiết kế bố trí",
      Router: "",
      Prefix: "RBPB7",
    },
    {
      // Number: "1.011911",
      name: "Đơn yêu cầu sửa đổi VBBH - Sáng chế",
      Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO,
      Prefix: "SB1",
    },
    {
      // Number: "1.011911",
      name: "Đơn yêu cầu sửa đổi VBBH - Giải pháp hữu ích",
      Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO,
      Prefix: "SB2",
    },
    {
      // Number: "1.011911",
      name: "Đơn yêu cầu sửa đổi VBBH - Kiểu dáng công nghiệp",
      Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO,
      Prefix: "SB3",
    },
    {
      // Number: "1.011911",
      name: "Đơn yêu cầu sửa đổi VBBH - Nhãn hiệu",
      Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO,
      Prefix: "SB4",
    },
    {
      // Number: "1.011911",
      name: "Đơn yêu cầu sửa đổi VBBH - Chỉ Dẫn Địa Lý",
      Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO,
      Prefix: "SB6",
    },
    {
      // Number: "1.011911",
      name: "Đơn yêu cầu sửa đổi VBBH - Thiết kế bố trí",
      Router: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO,
      Prefix: "SB7",
    },
    {
      // Number: "1.011904",
      name: "Sửa đổi đơn - Sáng chế",
      Router: ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐ1",
    },
    {
      // Number: "1.011904",
      name: "Sửa đổi đơn - Giải pháp hữu ích",
      Router: ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐ2",
    },
    {
      // Number: "1.011904",
      name: "Sửa đổi đơn - Kiểu dáng công nghiệp",
      Router: ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐ3",
    },
    {
      // Number: "1.011904",
      name: "Sửa đổi đơn - Nhãn hiệu",
      Router: ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐ4",
    },
    {
      // Number: "1.011904",
      name: "Sửa đổi đơn - Chỉ dẫn địa lý",
      Router: ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐ6",
    },
    {
      // Number: "1.011904",
      name: "Sửa đổi đơn - Thiết kế bố trí",
      Router: ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐ7",
    },
    {
      // Number: "1.011927",
      name: "Đơn đăng ký ghi nhận thay đổi về tổ chức dịch vụ đại diện sở hữu công nghiệp/người đại diện SHCN",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_THONG_TIN_CUA_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐĐD",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cấp lại Giấy chứng nhận tổ chức đủ điều kiện hoạt động giám định sở hữu công nghiệp",
      Router: "",
      Prefix: "SĐGĐ",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Sáng chế",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐLX1",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Giải pháp hữu ích",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐLX2",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Kiểu dáng công nghiệp",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐLX3",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Nhãn hiệu quốc gia",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐLX4",
    },
    {
      // Number: "1.011915",
      name: "Đơn yêu cầu sửa đổi hợp đồng chuyển quyền sử dụng đối tượng SHCN - Thiết kế bố trí",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
      Prefix: "SĐLX7",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu tra cứu - Sáng chế",
      Router: "",
      Prefix: "TC1",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu tra cứu - Giải pháp hữu ích",
      Router: "",
      Prefix: "TC2",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu tra cứu - Kiểu dáng công nghiệp",
      Router: "",
      Prefix: "TC3",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu tra cứu - Nhãn hiệu",
      Router: "",
      Prefix: "TC4",
    },
    {
      // Number: "1.011926",
      name: "Đơn đăng ký ghi nhận tổ chức dịch vụ đại diện sở hữu công nghiệp",
      Router:
        ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_TO_CHUC_DU_DIEU_KIEN_KINH_DOANH_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
      Prefix: "TCĐD",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu cấp Giấy chứng nhận tổ chức đủ điều kiện hoạt động giám định sở hữu công nghiệp",
      Router: "",
      Prefix: "TCGĐ",
    },
    {
      // Number: "",
      name: "Đơn yêu cầu xác nhận ĐKQTNH được chấp nhận bảo hộ tại Việt Nam",
      Router: "",
      Prefix: "XNQT",
    },
    {
      // Number: "1.011906",
      name: "Thủ tục rút đơn đăng ký SHCN",
      Router: "",
      Prefix: "RĐ1",
    },
    {
      // Number: "1.011906",
      name: "Thủ tục rút đơn đăng ký SHCN",
      Router: "",
      Prefix: "RĐ2",
    },
    {
      // Number: "1.011906",
      name: "Thủ tục rút đơn đăng ký SHCN",
      Router: "",
      Prefix: "RĐ3",
    },
    {
      // Number: "1.011906",
      name: "Thủ tục rút đơn đăng ký SHCN",
      Router: "",
      Prefix: "RĐ4",
    },
    {
      // Number: "1.011906",
      name: "Thủ tục rút đơn đăng ký SHCN",
      Router: "",
      Prefix: "RĐ6",
    },
    {
      // Number: "1.011906",
      name: "Thủ tục rút đơn đăng ký SHCN",
      Router: "",
      Prefix: "RĐ7",
    },
  ].filter(i => (!!MS ? (i.Number = "== MS ") : true))
