import {
  LIST_FEE_ID,
  pushToListFee,
} from "src/pages/ADMIN/FormDirect/components/commonFunc"
import DossierOffService from "src/services/DossierOffService"
import DossierService from "src/services/DossierService"

export const apiFee = (
  DossierCateCode, //Mã số loại tờ khai
) => {
  switch (DossierCateCode) {
    case "1.005253": // Sáng chế - GPHI
      return { ServiceName: DossierService, apiFeeName: "tinhPhiNopDon" }

    case "1.005323": // kiểu dáng công nghiệp
    case "1.005267": // Form 9: Thiết kế bố trí
    case "2.002126": //Nhãn hiệu
    case "1.005265": //Chỉ dẫn địa lý
    case "1.011902": //Thủ tục chuyển đổi đăng ký quốc tế nhãn hiệu thành đơn nộp theo thể thức quốc gia
      return {
        ServiceName: DossierOffService,
        apiFeeName: "tinhPhiDonXacLapQuyen",
      }
    case "1.011912": //Thủ tục cấp phó bản/cấp lại văn bằng bảo hộ đối tượng sở hữu công nghiệp
    case "1.011913": //Đăng ký hợp đồng chuyển nhượng quyền sở hữu công nghiệp
    case "1.011914": //Thủ tục đăng ký hợp đồng chuyển quyền sử dụng đối tượng
    case "1.011915": // Thủ tục ghi nhận việc sửa đổi nội dung, gia hạn, chấm dứt
    case "1.003966": // Thủ tục giải quyết khiếu nại liên quan đến sở hữu công nghiệp
    case "1.011907": //DUY TRÌ HIỆU LỰC VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
    case "1.011908": //GIA HẠN HIỆU LỰC VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
    case "1.011909": //CHẤM DỨT HIỆU LỰC VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
    case "1.011910": //HỦY BỎ HIỆU LỰC VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
    case "1.011911": //SỬA ĐỔI VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
    case "1.011916": //Yêu cầu bắt buộc chuyển giao quyền sử dụng sáng chế
      return {
        ServiceName: DossierOffService,
        apiFeeName: "TinhPhiDonLienQuanToiVanBang",
      }
    case "1.011904": //Thủ tục sửa đổi, bổ sung đơn đăng ký sở hữu công nghiệp
    case "1.011903": //Thủ tục yêu cầu ghi nhận thay đổi người nộp đơn đăng ký
      return {
        ServiceName: DossierOffService,
        apiFeeName: "TinhPhiDonLienQuanToiDon",
      }
    case "1.011930": //Tờ khai 25 - ĐĂNG KÝ NGHIỆP VỤ GIÁM ĐỊNH SỞ HỮU CÔNG NGHIỆP
    case "1.011931": //1.011.931 - Thủ tục cấp Thẻ giám định viên sở hữu công nghiệp (CCGĐ)
    case "1.011932": //1.011.932 - Thủ tục cấp lại Thẻ giám định viên sở hữu công nghiệp
    case "1.011921": //1.011.921 - Thủ tục đăng ký kiểm tra nghiệp vụ đại diện sở hữu công nghiệp
    case "1.011922": //1.011922. Thủ tục cấp Chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp
    case "1.011923": //1.011923. Thủ tục cấp lại Chứng chỉ hành nghề dịch vụ đại diện sở hữu công nghiệp
    case "1.011925": //1.011.925 - Thủ tục ghi nhận Người đại diện sở hữu công nghiệp (GNĐB)
    case "1.011926": //1.011.926 - Thủ tục ghi nhận tổ chức đủ điều kiện kinh doanh dịch vụ đại diện sở hữu công nghiệp (TCĐD)
    case "1.011927": //1.011.927 - Thủ tục ghi nhận thay đổi thông tin của tổ chức dịch vụ đại diện sở hữu công nghiệp (SĐĐD)
    case "1.011928": //Thủ tục xóa tên tổ chức dịch vụ đại diện sở hữu công
    case "1.011929": //Thủ tục xóa tên người đại diện sở hữu công nghiệp
    case "1.011935": //Thủ tục cấp lại Giấy chứng nhận tổ chức giám định sở hữu công nghiệp (SĐGĐ)
    case "1.011934": //Thủ tục cấp Giấy chứng nhận tổ chức giám định sở hữu công nghiệp (SĐGĐ)
    case "1.011897": //Thủ tục yêu cầu thẩm định nội dung đơn đăng ký sáng chế của người thứ ba
      return {
        ServiceName: DossierOffService,
        apiFeeName: "TinhPhiCacDonKhac",
      }
    case "1.011900": //Thủ tục xử lý đơn Madrid có nguồn gốc Việt Nam
      return {
        ServiceName: DossierOffService,
        apiFeeName: "tinhPhiDonXacLapQuyenQuocTe",
      }
    default:
      return ""
  }
}
export const convertMoneyFee = (
  typeForm,
  dataDefaltDoc,
  checkbox,
  formFile,
  infoFile,
  DossierCateCode, //Mã số loại tờ khai
  dossierLocal,
) => {
  let body = {}
  let bodyOld = {}
  let ListFee = []

  switch (DossierCateCode) {
    //================================== Body tính phí mới=========================================
    case "1.011900": //Thủ tục xử lý đơn Madrid có nguồn gốc Việt Nam
      body = {
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_43, 1)
      break
    case "1.011935": //Thủ tục cấp lại Giấy chứng nhận tổ chức giám định sở hữu công nghiệp (SĐGĐ)
    case "1.011934": //Thủ tục cấp Giấy chứng nhận tổ chức giám định sở hữu công nghiệp (SĐGĐ)
    case "1.011932": //1.011.932 - Thủ tục cấp lại Thẻ giám định viên sở hữu công nghiệp
    case "1.011931": //Thủ tục cấp Thẻ giám định viên sở hữu công nghiệp (CCGĐ)
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_25, 1)
      break
    case "1.011927": //1.011.927 - Thủ tục ghi nhận thay đổi thông tin của tổ chức dịch vụ đại diện sở hữu công nghiệp (SĐĐD)
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_25, 1)
      break
    case "1.011926": //1.011.926 - Thủ tục ghi nhận tổ chức đủ điều kiện kinh doanh dịch vụ đại diện sở hữu công nghiệp (TCĐD)
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_10, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_11, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_25, 1)
      break
    case "1.011925": //1.011.925 - Thủ tục ghi nhận Người đại diện sở hữu công nghiệp (GNĐB)
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)
      break

    case "1.011923": //Form 1.011923.RenewIndustrialRepCertificate
    case "1.011922":
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_9, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_10, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_11, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_25, 1)
      break
    case "1.011921": // ĐĂNG KÝ DỰ KIỂM TRA NGHIỆP VỤ ĐẠI DIỆN SỞ HỮU CÔNG NGHIỆP
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        SoMon: dossierLocal?.lengthData,
        ListFee: ListFee,
      }
      if (dossierLocal?.SubjectCate === 1) {
        if (body?.SoMon) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_24, body?.SoMon)
        }
      }
      if (dossierLocal?.SubjectCate === 2) {
        if (body?.SoMon) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_24, body?.SoMon)
        }
      }

      break
    case "1.011916": //Yêu cầu bắt buộc chuyển giao quyền sử dụng sáng chế
    case "1.011914": //ĐĂNG KÝ HỢP ĐỒNG CHUYỂN QUYỀN SỬ DỤNG ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        SoVBBH: dossierLocal?.VBBHNumber?.split(",")?.length,
        ListFee: ListFee,
      }

      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_5, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_19, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)

      if (body?.SoVBBH) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_19, body?.SoVBBH - 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, body?.SoVBBH - 1)
      }
      break
    case "1.011913": //ĐĂNG KÝ HỢP ĐỒNG CHUYỂN NHƯỢNG QUYỀN SỞ HỮU CÔNG NGHIỆP
      body = {
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        SoVBBH: dossierLocal?.VBBHNumber?.split(",")?.length,
        SoNhom: dossierLocal?.GroupNumber,
        ContractObject: dossierLocal?.ContractObject,
        ListFee: ListFee,
      }
      if ((body?.SoVBBH && body?.SoNhom === undefined) || body?.SoNhom === 0) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_19, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_19, body?.SoVBBH - 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, body?.SoVBBH - 1)
      }
      if (body?.SoNhom) {
        const SoNhomInt = parseInt(body?.SoNhom, 10)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_19, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, SoNhomInt)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, body?.SoVBBH - 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_19, body?.SoVBBH - 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, body?.SoVBBH - 1)
      }
      break
    case "1.011912": //CẤP PHÓ BẢN/CẤP LẠI VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)
      break
    case "1.011911": //SỬA ĐỔI VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        SoVBBH: dossierLocal?.lengthData,
        VBBHType: dossierLocal?.VBBHType,
        SoYCBH: dossierLocal?.SoYCBH,
        SoHinhAnhThem: dossierLocal?.Document?.length,
        SoTrangTangThem: dossierLocal?.SoTrangTangThem,
        SelectModifications: dossierLocal?.SelectModifications,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)
      if (body?.SoVBBH > 0) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, body?.SoVBBH - 1)
      }
      if (body?.SoHinhAnhThem > 1) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, body?.SoHinhAnhThem - 1)
      }
      break
    case "1.011908": //GIA HẠN HIỆU LỰC VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
      let GroupNumberForm908 = 0
      let SoThangNopMuonForm908 = 0
      let SoVBBHForm908 = 0
      let SoPAGHForm908 = 0
      let SoGHSPHForm908 = 0
      if (dossierLocal?.Extends?.ExtensionObject === 1) {
        JSON.parse(dossierLocal?.Extends?.ObjectKDCN).forEach(i => {
          GroupNumberForm908 =
            dossierLocal?.Extends?.ObjectKDCN?.split(",")?.length / 5
          SoThangNopMuonForm908 += i.TimeLate
          SoVBBHForm908 += i.NumberDiplomas
          SoPAGHForm908 += i.Plan
        })
      }
      if (dossierLocal?.Extends?.ExtensionObject === 2) {
        JSON.parse(dossierLocal?.Extends?.ObjectDKNH).forEach(e => {
          GroupNumberForm908 =
            dossierLocal?.Extends?.ObjectDKNH?.split(",")?.length / 5
          SoThangNopMuonForm908 += e.TimeLate
          SoVBBHForm908 += e.NumberDiplomas
          SoGHSPHForm908 += e.RenewalProductsServices
        })
      }
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
      if (dossierLocal?.Extends?.ExtensionObject === 1) {
        if (SoVBBHForm908) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, GroupNumberForm908)
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, GroupNumberForm908)
        }
        if (SoPAGHForm908) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_6, GroupNumberForm908)
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_33, GroupNumberForm908)
        }
        if (SoThangNopMuonForm908) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_7, SoThangNopMuonForm908)
        }
      }
      if (dossierLocal?.Extends?.ExtensionObject === 2) {
        if (SoVBBHForm908) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, GroupNumberForm908)
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, GroupNumberForm908)
        }
        if (SoGHSPHForm908) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_6, GroupNumberForm908)
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_33, GroupNumberForm908)
        }
        if (SoThangNopMuonForm908) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_7, SoThangNopMuonForm908)
        }
      }
      break

    case "1.011907": //DUY TRÌ HIỆU LỰC VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        IsSC_GPHI: true,
        SoVBBH: dossierLocal?.Extends?.NumberDiplomas,
        SoYCBH: dossierLocal?.Extends?.IndependentProtection,
        SoNam: +dossierLocal?.Extends?.YearMaintenance,
        SoThang: dossierLocal?.TimeLate,
        SoThangVBBH: dossierLocal?.SoThangVBBH,
        ListFee: ListFee,
      }

      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)

      if (body?.SoYCBH) {
        const SoYCBHInt = parseInt(body?.SoYCBH, 10)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_6, SoYCBHInt)
      }
      if (body?.SoThang) {
        const SoThangInt = parseInt(body?.SoThang, 10)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_7, SoThangInt)
      }

      if (body?.SoNam) {
        const feeIdMap = [
          LIST_FEE_ID?.FeeID_34,
          LIST_FEE_ID?.FeeID_50,
          LIST_FEE_ID?.FeeID_35,
          LIST_FEE_ID?.FeeID_51,
          LIST_FEE_ID?.FeeID_36,
          LIST_FEE_ID?.FeeID_52,
          LIST_FEE_ID?.FeeID_37,
          LIST_FEE_ID?.FeeID_53,
          LIST_FEE_ID?.FeeID_38,
          LIST_FEE_ID?.FeeID_54,
          LIST_FEE_ID?.FeeID_39,
          LIST_FEE_ID?.FeeID_55,
          LIST_FEE_ID?.FeeID_56,
          LIST_FEE_ID?.FeeID_40,
          LIST_FEE_ID?.FeeID_57,
          LIST_FEE_ID?.FeeID_58,
          LIST_FEE_ID?.FeeID_41,
          LIST_FEE_ID?.FeeID_59,
          LIST_FEE_ID?.FeeID_60,
          LIST_FEE_ID?.FeeID_61,
        ]

        if (body?.SoNam >= 1 && body?.SoNam <= 20) {
          const SoYCBHInt = parseInt(body?.SoYCBH, 10)
          pushToListFee(ListFee, feeIdMap[body?.SoNam - 1], SoYCBHInt)
        }
      }

      break
    case "1.011903": //Form 22
      let SoDonTruocHopLeForm22 = 0
      let SoDonSauHopLeForm22 = 0
      let GroupNumberForm22 = 0
      JSON.parse(dossierLocal?.VBBHNumber).forEach(i => {
        GroupNumberForm22 = dossierLocal?.VBBHNumber?.split(",")?.length / 2
        if (i[1]) {
          SoDonSauHopLeForm22 += 1
        } else {
          SoDonTruocHopLeForm22 += 1
        }
      })
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        //Số đơn trước hợp lệ
        SoDonTrcHopLe: SoDonTruocHopLeForm22,
        //Số đơn sau hợp lệ
        SoDonSauHopLe: SoDonSauHopLeForm22,
        ListFee: ListFee,
      }
      if (SoDonTruocHopLeForm22) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_18, SoDonTruocHopLeForm22)
      }
      if (SoDonSauHopLeForm22) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_18, SoDonSauHopLeForm22)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, SoDonSauHopLeForm22)
      }

      if (GroupNumberForm22 > 0) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, GroupNumberForm22)
      }
      break
    case "1.011904": //Form 21
      let SoDonTruocHopLeForm21 = 0
      let SoDonSauHopLeForm21 = 0
      let GroupNumberForm21 = 0
      JSON.parse(dossierLocal?.VBBHNumber).forEach(i => {
        GroupNumberForm21 = dossierLocal?.VBBHNumber?.split(",")?.length / 2
        if (i[1]) {
          SoDonSauHopLeForm21 += 1
        } else {
          SoDonTruocHopLeForm21 += 1
        }
      })
      const sohinhForm21 = dataDefaltDoc?.Data?.filter(
        item => +item?.ParentID === 2,
      )?.length
      const totalSoTrangForm21 =
        formFile.getFieldValue(`8`)?.reduce((total, currentValue) => {
          if (!!currentValue?.ObjectFileID)
            return total + currentValue?.NumberPageFile
          else
            return (
              total +
              (infoFile?.find(item => item?.uid === currentValue?.uid)
                ?.NumberPage || 0)
            )
        }, 0) || 0
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        //Tổng số nội dung sửa đổi
        NumberEdit:
          dossierLocal?.contentModifications?.length ||
          dossierLocal?.Modifications?.length,
        //Số trang bản mô tả
        SoTrangTangThem: totalSoTrangForm21 > 6 ? totalSoTrangForm21 - 6 : 0,
        SoHinhAnhThem: sohinhForm21 || 0,
        //Số đơn trước hợp lệ
        SoDonTrcHopLe: SoDonTruocHopLeForm21,
        //Số đơn sau hợp lệ
        SoDonSauHopLe: SoDonSauHopLeForm21,

        ListFee: ListFee,
      }

      if (SoDonTruocHopLeForm21) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_18, SoDonTruocHopLeForm21)
      }
      if (SoDonSauHopLeForm21) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, SoDonSauHopLeForm21)
      }

      if (GroupNumberForm21 > 0) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, GroupNumberForm21)
      }

      if (body?.NumberEdit) {
        pushToListFee(
          ListFee,
          LIST_FEE_ID?.FeeID_17,
          dossierLocal?.NumberEdit *
            (SoDonSauHopLeForm21 + SoDonTruocHopLeForm21),
        )
      }

      if (sohinhForm21 > 1) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, SoHinhAnhThem)
      }

      if (totalSoTrangForm21) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_31, SoTrangTangThem)
      }

      break
    case "1.005253": //Form1
      const sohinh = dataDefaltDoc?.Data?.filter(
        item => +item?.ParentID === 2,
      )?.length
      let SoUuTien = 0
      if (!!dossierLocal?.RequestPriorityRight)
        SoUuTien = dossierLocal?.Priorities?.length || 0
      let SoSuaDoi = 0
      if (checkbox?.ProfileOrigin5) {
        SoSuaDoi = SoSuaDoi + (dossierLocal?.Modifications?.length || 0)
      }
      const totalSoTrang =
        formFile.getFieldValue(`8`)?.reduce((total, currentValue) => {
          if (!!currentValue?.ObjectFileID)
            return total + currentValue?.NumberPageFile
          else
            return (
              total +
              (infoFile?.find(item => item?.uid === currentValue?.uid)
                ?.NumberPage || 0)
            )
        }, 0) || 0
      bodyOld = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,

        IsTĐND: dossierLocal?.IsRequestContentAppraisal,
        //Số trang bản mô tả
        SoTrang: totalSoTrang,
        // dossierLocal?.Document?.find(item => item?.DocumentID === 8)
        //   ?.NumberPage || 0,
        //"trong hình ảnh công bố"
        SoHinhAnh: sohinh || 0,

        //Điển bảo hộ
        DiemBaoHo: +dossierLocal?.IndependentProtectionPoint,

        //Số chủ đơn
        SoDoiTuong: dossierLocal?.ApplicationOwner?.length,
        //Phân loại IPC
        IsPhanLoai: dossierLocal?.IsClassify,
        //Quyền hưởng ưu tiên
        IsUuTien: !!dossierLocal?.RequestPriorityRight,
        SoSuaDoi: SoSuaDoi,
        SoUuTien: SoUuTien,
        //PHÂN LOẠI SÁNG CHẾ QUỐC TẾ (IPC)
        SoLuongPhanLoai: 0,
        // NumberEdit: 0,
        //Có là đơn chuyển đổi không
        IsChuyenDoi: !!checkbox?.ProfileOrigin3,
        //trước Quyết định hợp lệ là true
        IsTruocQDHopLe: !!(checkbox?.ValidDecision === 1),

        //Là đơn tách thì true không thì false
        IsTachDon: !!checkbox?.ProfileOrigin2,
      }

      if (bodyOld?.IsChuyenDoi) {
        if (bodyOld?.IsTruocQDHopLe)
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_1, 1)
        else {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_1, 1)
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
        }
      } else {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_1, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)

        //Tách đơn
        // pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, bodyOld?.DiemBaoHo)
        // pushToListFee(
        //   ListFee,
        //   LIST_FEE_ID?.FeeID_13,
        //   bodyOld?.SoTrang > 6 ? bodyOld?.SoTrang - 6 : 0,
        // )
        // pushToListFee(
        //   ListFee,
        //   LIST_FEE_ID?.FeeID_30,
        //   bodyOld?.SoHinhAnh > 1 ? bodyOld?.SoHinhAnh - 1 : 0,
        // )

        if (bodyOld?.DiemBaoHo) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, bodyOld?.DiemBaoHo)
        }
        if (bodyOld?.SoTrang) {
          //Số trang tính từ trang thứ 7 trở đi
          pushToListFee(
            ListFee,
            LIST_FEE_ID?.FeeID_13,
            bodyOld?.SoTrang > 6 ? bodyOld?.SoTrang - 6 : 0,
          )
          pushToListFee(
            ListFee,
            LIST_FEE_ID?.FeeID_31,
            bodyOld?.SoTrang > 6 ? bodyOld?.SoTrang - 6 : 0,
          )
        }
        if (bodyOld?.SoHinhAnh) {
          //Số hình từ hình thứ 2 trở đi
          pushToListFee(
            ListFee,
            LIST_FEE_ID?.FeeID_30,
            bodyOld?.SoHinhAnh > 1 ? bodyOld?.SoHinhAnh - 1 : 0,
          )
        }
        if (bodyOld?.IsPhanLoai) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, 1)
        }
        if (bodyOld?.IsTĐND) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_48, bodyOld?.DiemBaoHo)
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, bodyOld?.DiemBaoHo)
          //Số trang tính từ trang thứ 7 trở đi
          pushToListFee(
            ListFee,
            LIST_FEE_ID?.FeeID_49,
            bodyOld?.SoTrang > 6 ? bodyOld?.SoTrang - 6 : 0,
          )
        }
        if (bodyOld?.SoSuaDoi) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_17, bodyOld?.SoSuaDoi)
        }
        if (!bodyOld?.IsTachDon && bodyOld?.IsUuTien) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, bodyOld?.SoUuTien)
        }
      }

      //Convert sang phí mới
      body = {
        DossierCateID: bodyOld?.DossierCateID,
        ListFee: ListFee,
      }
      break
    case "2.002126": //Form 6 đăng ký nhãn hiệu
      let SoUuTienForm6 = 0
      if (!!dossierLocal?.RequestPriorityRight)
        SoUuTienForm6 = dossierLocal?.Priorities?.length || 0
      const SoTrangTangThem =
        dossierLocal?.CommodityGroups?.reduce((total, currentValue) => {
          if (currentValue?.Total > 6) {
            return total + (currentValue?.Total - 6)
          } else {
            return total
          }
        }, 0) || 0
      bodyOld = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        // Kết quả thẩm định nội dung
        KQ_TĐND_NH: dossierLocal?.IsHasResult === 2 ? true : false,
        //Tổng số nhóm hàng hóa/dịch vụ
        NumberItem: dossierLocal?.CommodityGroups?.length || 0,
        //Số trang bản mô tả
        SoTrangTangThem: SoTrangTangThem,
        //Tách đơn
        IsTachDon: !!dossierLocal?.ProfileOrigin2,
        // Phí khác
        IsPhanLoai: !!dossierLocal?.IsClassify,
        // Số ưu tiên
        SoUuTien: SoUuTienForm6,
        // Tách mẫu
        TachMau: dossierLocal?.IsFirstOrigin === 1 ? true : false,
      }
      //Mặc định
      let defaultFee = [
        { FeeID: LIST_FEE_ID?.FeeID_1, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
      ]
      //Không có kết quả và có tách mẫu thì tính bình thường
      if (bodyOld?.NumberItem) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, bodyOld?.NumberItem)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, bodyOld?.NumberItem)
      }

      if (bodyOld?.SoUuTien) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, bodyOld?.SoUuTien)
      }
      if (bodyOld?.SoTrangTangThem) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_13, bodyOld?.SoTrangTangThem)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_28, bodyOld?.SoTrangTangThem)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_15, bodyOld?.SoTrangTangThem)
      }
      if (bodyOld?.IsPhanLoai) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, 1)
      }
      if (bodyOld?.IsTachDon && !!(bodyOld?.KQ_TĐND_NH || !bodyOld?.TachMau))
        //ListFee = list phí mặc định
        //Convert sang phí mới
        body = {
          DossierCateID: bodyOld?.DossierCateID,
          ListFee: defaultFee,
        }
      //Convert sang phí mới
      else {
        body = {
          DossierCateID: bodyOld?.DossierCateID,
          ListFee: [...defaultFee, ...ListFee],
        }
      }
      break
    case "1.005265": //Form 8 đăng ký chỉ dẫn địa lý
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        // DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        ListFee: [
          { FeeID: LIST_FEE_ID?.FeeID_1, Quantity: 1 },
          { FeeID: LIST_FEE_ID?.FeeID_12, Quantity: 1 },
          { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
          { FeeID: LIST_FEE_ID?.FeeID_27, Quantity: 1 },
        ],
      }
      break
    case "1.005267": //Form 9: Thiết kế bố trí
      let sohinhForm9 = 0
      dataDefaltDoc?.Data?.filter(
        item => item?.Code === "BO_ANH_CHUP",
      )?.forEach((itemAnh, idx) => {
        let countFile = formFile?.getFieldValue(`${itemAnh?.DocID}`)?.length
        if (countFile) sohinhForm9 = sohinhForm9 + countFile
      })
      //Tính từ ảnh thứ 2 trở đi nên trừ thêm 1 nữa
      //Suy ra trừ 2
      sohinhForm9 = sohinhForm9 ? sohinhForm9 - 1 : 0
      let SoHinhAnhThem = sohinhForm9 || 0
      ListFee = [
        { FeeID: LIST_FEE_ID?.FeeID_1, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_12, Quantity: 1 },
        { FeeID: LIST_FEE_ID?.FeeID_29, Quantity: 1 },
      ]
      if (SoHinhAnhThem) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_30, SoHinhAnhThem)
      }
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        // DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        // SoHinhAnhThem: sohinhForm9 || 0,

        ListFee: ListFee,
      }
      break

    // ==========================================================================================
    case "1.005323": // Tờ khai kiểu dáng công nghiệp
      const NumberItem = dataDefaltDoc?.Data?.filter(
        i => i.Code === "BO_ANH_CHUP" && i.Level === 3,
      )?.length
      const sohinh_Form5 = dataDefaltDoc?.Data?.filter(
        item => item?.Level === 4,
      )?.length
      let SoUuTienForm5 = 0
      if (!!dossierLocal?.RequestPriorityRight)
        SoUuTienForm5 = dossierLocal?.Priority?.length || 0

      body = {
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        NumberItem: NumberItem ? NumberItem : 0,
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        SoHinhAnhThem: sohinh_Form5 || 0,
        IsPhanLoai: dossierLocal?.IsClassify,
        IsTachDon: !!dossierLocal?.ProfileOrigin,
        SoDonGoc: dossierLocal?.SingleSeparationNo,
        SoUuTien: SoUuTienForm5,
        KQ_TĐND: dossierLocal?.IsHasResult,
        DonBanDau: dossierLocal?.IsFirstOrigin,
        ListFee: ListFee,
      }
      if (
        (dossierLocal?.ProfileOrigin === "2,4" &&
          dossierLocal?.IsHasResult === undefined) ||
        (dossierLocal?.ProfileOrigin === "2" &&
          dossierLocal?.IsHasResult === 0) ||
        dossierLocal?.ProfileOrigin === "4" ||
        dossierLocal?.ProfileOrigin === ""
      ) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_1, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
        if (body?.NumberItem) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, NumberItem)
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, NumberItem)
        }
        if (body?.SoHinhAnhThem) {
          pushToListFee(
            ListFee,
            LIST_FEE_ID?.FeeID_30,
            !!sohinh_Form5 ? sohinh_Form5 - 1 : 0,
          )
        }
        if (dossierLocal?.RequestPriorityRight) {
          pushToListFee(
            ListFee,
            LIST_FEE_ID?.FeeID_16,
            dossierLocal?.RequestPriorityRight,
          )
        }
        if (dossierLocal?.IsClassify) {
          pushToListFee(ListFee, LIST_FEE_ID?.FeeID_14, 1)
        }
      }
      if (
        (dossierLocal?.ProfileOrigin === "2" &&
          dossierLocal?.IsHasResult === 1) ||
        (dossierLocal?.ProfileOrigin === "2,4" &&
          dossierLocal?.IsHasResult === 1)
      ) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_1, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_16, 1)
      }
      if (
        (dossierLocal?.ProfileOrigin === "2" &&
          dossierLocal?.IsHasResult === 2) ||
        (dossierLocal?.ProfileOrigin === "2,4" &&
          dossierLocal?.IsHasResult === 2)
      ) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_1, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
        pushToListFee(
          ListFee,
          LIST_FEE_ID?.FeeID_30,
          !!sohinh_Form5 ? sohinh_Form5 - 1 : 0,
        )
        pushToListFee(
          ListFee,
          LIST_FEE_ID?.FeeID_16,
          dossierLocal?.RequestPriorityRight,
        )
      }
      if (
        (dossierLocal?.ProfileOrigin === "2" &&
          dossierLocal?.IsHasResult === 3) ||
        (dossierLocal?.ProfileOrigin === "2,4" &&
          dossierLocal?.IsHasResult === 3)
      ) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_1, 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_12, NumberItem)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_27, NumberItem)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
        pushToListFee(
          ListFee,
          LIST_FEE_ID?.FeeID_30,
          !!sohinh_Form5 ? sohinh_Form5 - 1 : 0,
        )
        pushToListFee(
          ListFee,
          LIST_FEE_ID?.FeeID_16,
          dossierLocal?.RequestPriorityRight,
        )
      }
      break
    case "1.011915": //Form23
      let DataVVBHNumber = JSON.parse(dossierLocal?.VBBHNumber)
      let result = DataVVBHNumber?.map(item => item[1]?.split(","))
      let VVBHNumber = result.reduce((acc, curr) => acc + curr.length, 0)
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        SoVBBH: VVBHNumber,
        SelectModifications:
          dossierLocal?.Modifications[0]?.SelectModifications,
        // HinhThuc: dossierLocal?.ContractObject,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)

      if (body?.SoVBBH) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_20, body?.SoVBBH - 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, body?.SoVBBH - 1)
      }

      break
    case "1.011930": //Tờ khai 25 - ĐĂNG KÝ NGHIỆP VỤ GIÁM ĐỊNH SỞ HỮU CÔNG NGHIỆP
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        SoVanBang_Mon: dossierLocal?.lengthData,
        ListFee: ListFee,
      }
      if (body?.SoVanBang_Mon) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_24, body?.SoVanBang_Mon)
      }
      break
    case "1.011909": // 1.011909-1.011910 - CHẤM DỨT/HỦY BỎ HIỆU LỰC VĂN BẰNG BẢO HỘ ĐỐI TƯỢNG SỞ HỮU CÔNG NGHIỆP
    case "1.011910":
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        SoVBBH: dossierLocal?.lengthData,
        HinhThuc: dossierLocal?.HinhThuc,
        IsChuVBBH: dossierLocal?.IsChuVBBH,
        ListFee: ListFee,
      }
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_8, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_21, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_29, 1)
      pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, 1)

      if (body?.SoVBBH) {
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_21, body?.SoVBBH - 1)
        pushToListFee(ListFee, LIST_FEE_ID?.FeeID_32, body?.SoVBBH - 1)
      }
      break
    case "1.011897": //Thủ tục yêu cầu thẩm định nội dung đơn đăng ký sáng chế của người thứ ba
      const totalSoTrangForm42 =
        formFile.getFieldValue(`8`)?.reduce((total, currentValue) => {
          if (!!currentValue?.ObjectFileID)
            return total + currentValue?.NumberPageFile
          else
            return (
              total +
              (infoFile?.find(item => item?.uid === currentValue?.uid)
                ?.NumberPage || 0)
            )
        }, 0) || 0
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        //Điểm YCBH
        SoVanBang_Mon: +dossierLocal?.IndependentProtectionPoint,
        //Số trang bản mô tả
        SoTrangTangThem: totalSoTrangForm42 > 6 ? totalSoTrangForm42 - 6 : 0,
      }
      break
    case "1.011902": //Thủ tục chuyển đổi đăng ký quốc tế nhãn hiệu thành đơn nộp theo thể thức quốc gia
      const SoTrangTangThemForm37 =
        dossierLocal?.CommodityGroups?.reduce((total, currentValue) => {
          if (currentValue?.Total > 6) {
            return total + (currentValue?.Total - 6)
          } else {
            return total
          }
        }, 0) || 0
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
        //Tổng số nhóm hàng hóa/dịch vụ
        NumberItem: dossierLocal?.CommodityGroups?.length || 0,
        //Số trang bản mô tả
        SoTrangTangThem: SoTrangTangThemForm37,
        // Phí khác
        IsPhanLoai: !!dossierLocal?.IsClassify,
        // Đã được chấp nhận bảo hộ tại Việt Nam
        IsAccept: !!dossierLocal?.IsProtected,
      }
      break
    case "1.011928": //Thủ tục xóa tên tổ chức dịch vụ đại diện sở hữu công
    case "1.011929": //Thủ tục xóa tên người đại diện sở hữu công nghiệp
    case "1.003966": //Thủ tục giải quyết khiếu nại liên quan đến sở hữu công nghiệp
      body = {
        //DossierCateID
        DossierCateID: typeForm?.DossierCateID || dossierLocal?.DossierCateID,
        //Code
        DossierCateCode: typeForm?.CodeTTHC || DossierCateCode,
      }
      break
    default:
      body = {}
      break
  }
  return {
    ...body,
    IsOnl: 1, //Nhạn biết đơn online
  }
}
