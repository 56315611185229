import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  searchSingleNow: "",
  searchOwnerNow: "",
  openModalSearchSingle: false,
}

export const singleSlice = createSlice({
  name: "single",
  initialState,
  reducers: {
    setSearchSingleNow: (state, action) => {
      console.log("action.payload", action.payload)

      state.searchSingleNow = action.payload
    },
    setSearchOwnerNow: (state, action) => {
      console.log("action.payload", action.payload)

      state.searchOwnerNow = action.payload
    },
    setOpenModalSearchSingle: (state, action) => {
      console.log("action.payload", action.payload)

      state.openModalSearchSingle = action.payload
    },
    setDiffModalSearchSingle: (state, action) => {
      console.log("action.payload", action.payload)

      state.openModalSearchSingle = !state.openModalSearchSingle
    },
  },
})

export const {
  setSearchSingleNow,
  setSearchOwnerNow,
  setOpenModalSearchSingle,
  setDiffModalSearchSingle,
} = singleSlice.actions

export default singleSlice.reducer
