import { Spin } from "antd"
import React from "react"
import { useRoutes } from "react-router-dom"
import ROUTER from "./index"
import RegisterIndustrialIndustrial from "src/pages/USER/ListPatternForm/1.011921.RegisterIndustrialIndustrial"

// ANONYMOUS
const PublicRouters = React.lazy(() => import("src/pages/PublicRouters"))
const SvgViewer = React.lazy(() => import("src/pages/SvgViewer"))

const NotFound = React.lazy(() => import("src/pages/NotFound"))
const Home = React.lazy(() => import("src/pages/ANONYMOUS/Home"))
const LoginPage = React.lazy(() => import("src/pages/ANONYMOUS/LoginPage"))
const RegisterPage = React.lazy(() => import("src/pages/ANONYMOUS/Register"))
const HomeSupport = React.lazy(() => import("src/pages/ANONYMOUS/Support"))
const Procedure = React.lazy(() => import("src/pages/ANONYMOUS/Procedure"))
const ProcedurePatentRegistration = React.lazy(() =>
  import("src/pages/ANONYMOUS/Procedure/components/Conten/PatentRegistration"),
)
const SearchResult = React.lazy(() =>
  import("src/pages/ANONYMOUS/SearchResult"),
)
const ForgotPassword = React.lazy(() =>
  import("src/pages/ANONYMOUS/ForgetPassword"),
)

// USER
const PrivateRoutes = React.lazy(() => import("src/pages/PrivateRoutes"))
const ChangePassword = React.lazy(() =>
  import("src/components/Layouts/component/ChangePassword"),
)
const TestPrint = React.lazy(() => import("src/pages/USER/TestPrintf"))
const PaymentSuccess = React.lazy(() =>
  import("src/pages/USER/ListProfile/components/PaymentPage/PaymentSuccess"),
)
const Declaration = React.lazy(() => import("src/pages/USER/Declaration"))

const ListDeclarationForm = React.lazy(() =>
  import("src/pages/USER/ListDeclarationForm"),
)
const PatentRegistration = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.PatentRegistration"),
)
const PatentRegistrationBrand = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/2.002126_PatentRegistrationBrand"),
)
const TrademarkRegistrationVN = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011900.TrademarkRegistrationVN"),
)

const ConvertInternationalTrademarkRegistration = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011902.ConvertInternationalTrademarkRegistration"
  ),
)

const LayoutDesign = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.005267_LayoutDesign"),
)

const RenewPaternt = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011912.RenewPaternt"),
)

const ForcedTransfer = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011916.ForcedTransfer"),
)

const ModifiedIndustialRegistration = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011904_ModifiedIndustialRegistration"
  ),
)

const ComplaintForm = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.003966_ComplaintForm"),
)

const AttendProfessionalExam = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011930_AttendProfessionalExam"),
)

const AssessorCard = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011931_32.AssessorCard"),
)

const CertificationEvaluateIndustrial = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011934_35.CertificationEvaluateIndustrial"
  ),
)
const RegistrationIndustrialJoinTest = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/30.RegistrationIndustrialJoinTest"),
)

const IndustrialRepCertificateReq = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011922.IndustrialRepCertificateReq"),
)

const RenewIndustrialRepCertificate = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011923.RenewIndustrialRepCertificate"
  ),
)

const RequirementsRegisterName = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/33.RequirementsRegisterName"),
)
const RecognitionOfIndustrialPropertyRepresentatives = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011925.RecognitionOfIndustrialPropertyRepresentatives"
  ),
)
const RecognizeSufficientBusinessConditions = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011926.RecognizeSufficientBusinessConditions"
  ),
)
const RecordChangesInformation = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011927.RecordChangesInformation"),
)
const ProposedDelegatingPower = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011919.ProposedDelegatingPower"),
)
const AllowOthersUseDegree = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011920.AllowOthersUseDegree"),
)

const RenewAndMaintainDiplomas = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011.907_08.RenewAndMaintainDiplomas"
  ),
)
const RequestDeletePresentative = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011.928_29.RequestDeletePresentative"
  ),
)
const CompensationForOwnerSHCN = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011.898.CompensationForOwnerSHCN"),
)
// const TrademarkRegistration = React.lazy(() =>
//   import("src/pages/USER/ListPatternForm/6.TrademarkRegistration"),
// )
const GeographicalRegistration = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.005265_GeographicalRegistration"),
)
const RegistrationContractTransfer = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011913_PatentRegistrtionContractTransfer"
  ),
)
const RegistrationTransferRightToUse = React.lazy(() =>
  import(
    "src/pages/USER/ListPatternForm/1.011914_PatentRegistrtionTransferRightToUse"
  ),
)
const RequestTransfer = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011903_PatentRequestTransfer"),
)
const RequestRemember = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011915_PatentRequestRemember"),
)

const EditProtectionText = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011911_EditProtectionText"),
)

const TerminationDetails = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011.909_10.TerminationDetails"),
)

// const RegitrationIndustrial = React.lazy(() =>
//   import("src/pages/USER/RegitrationIndustrial5"),
// )
//form5 new
const RegitrationIndustrial = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.005323_IndustrialRegistration"),
)

const AppraisalPatentApplications = React.lazy(() =>
  import("src/pages/USER/ListPatternForm/1.011897.AppraisalPatentApplications"),
)

const Support = React.lazy(() => import("src/pages/USER/Support"))
const ActivityLog = React.lazy(() => import("src/pages/USER/HistoryActive"))
const ListProfile = React.lazy(() => import("src/pages/USER/ListProfile"))
const ListDiploma = React.lazy(() => import("src/pages/USER/ListDiploma"))
const GroupPayment = React.lazy(() => import("src/pages/USER/GroupPayment"))

const AuthorizedPerson = React.lazy(() =>
  import("src/pages/USER/AuthorizedPerson"),
)
const PersonProfile = React.lazy(() => import("src/pages/USER/PersonProfile"))
const ConfigSignature = React.lazy(() =>
  import("src/pages/USER/ConfigSignature"),
)
const ListRequestSupport = React.lazy(() =>
  import("src/pages/USER/Support/components/ListRequestSupport.js"),
)
const GuideUsing = React.lazy(() =>
  import("src/pages/USER/Support/components/GuideUsing.js"),
)
const Question = React.lazy(() => import("src/pages/USER/Question"))
// const PatentRegistrationDeclaration = React.lazy(() => import("src/pages/USER/TestPrintf/index.js"))

// ADMIN
const AminRoutes = React.lazy(() => import("src/pages/ADMIN/AminRoutes"))
const Role = React.lazy(() => import("src/pages/ADMIN/Role"))
const PositionManager = React.lazy(() =>
  import("src/pages/ADMIN/PositionManager"),
)
const HistoryActive = React.lazy(() => import("src/pages/ADMIN/HistoryActive"))
const SystemCatePage = React.lazy(() => import("src/pages/ADMIN/SystemCate"))
const DossierCatePage = React.lazy(() => import("src/pages/ADMIN/DossierCate"))
const RepresentativeOrganizationsDirectory = React.lazy(() =>
  import("src/pages/ADMIN/RepresentativeOrganizationsDirectory"),
)
const Representative = React.lazy(() =>
  import("src/pages/ADMIN/Representative"),
)

const EmployeeContact = React.lazy(() =>
  import("src/pages/ADMIN/EmployeeContact"),
)
const CustomerDirector = React.lazy(() =>
  import("src/pages/ADMIN/CustomerDirector"),
)
const RegistrationForm = React.lazy(() =>
  import("src/pages/ADMIN/RegistrationForm"),
)
const SystemHistory = React.lazy(() => import("src/pages/ADMIN/SystemHistory"))
// const ProvideNumberForm = React.lazy(() =>
//   import("src/pages/ADMIN/ProvideNumberForm"),
// )
const PaymentManagementDirect = React.lazy(() =>
  import("src/pages/ADMIN/PaymentManagementDirect"),
)
const ReceiptList = React.lazy(() =>
  import("src/pages/ADMIN/PaymentManagementDirect/components/ReceiptList"),
)
const FormDirect = React.lazy(() => import("src/pages/ADMIN/FormDirect"))
const ManagerListFormOnline = React.lazy(() =>
  import("src/pages/ADMIN/ManagerListForm"),
)
const ManagerListFormFee = React.lazy(() =>
  import("src/pages/ADMIN/ManagerListFormFee"),
)
const SignResult = React.lazy(() => import("src/pages/ADMIN/SignResult"))
const SignVBBH = React.lazy(() => import("src/pages/ADMIN/SignVBBH"))
const ReportForm = React.lazy(() => import("src/pages/ADMIN/ReportForm"))
const Statistic = React.lazy(() => import("src/pages/ADMIN/Statistic"))
const RequestSupport = React.lazy(() =>
  import("src/pages/ADMIN/RequestSupport"),
)
const RequestUpdateInfo = React.lazy(() =>
  import("src/pages/ADMIN/RequestUpdateInfo"),
)
const FundManager = React.lazy(() => import("src/pages/ADMIN/FundManager"))
const ListFormIT = React.lazy(() => import("src/pages/ADMIN/ListFormIT"))
const SurplusManager = React.lazy(() =>
  import("src/pages/ADMIN/SurplusManager"),
)

//Ho So Truc Tiep - QL ho so don
//Huong dan thu tuc
const InstructionRegistration = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/1.Registration"),
)
const InstructionRegistrationOriginVN = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/2.RegistrationOriginVN"),
)
const InstructionRegistrationAssignOriginVN = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/3.RegistrationAssignOriginVN"),
)
const InstructionRegistrationSelectedVN = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/4.RegistrationSelectedVN"),
)
const InstructionRegistrationIndustrial = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/5.RegistrationIndustrial"),
)
const InstructionRegistrationBrand = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/6.RegistrationBrand"),
)
const InstructionRegistrationBrandOriginVN = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/7.RegistrationBrandOriginVN"),
)

const InstructionRegistrationGeographical = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/8.RegistrationGeographical"),
)

const PatentRegistrationSemiconductor = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/9.PatentRegistrationSemiconductor"),
)

const InstructionContractTransder = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/10.RegistrationContractTransfer"),
)

const InstructionTransferRightToUse = React.lazy(() =>
  import(
    "src/pages/ANONYMOUS/Instructions/11.PatentRegistrtionTransferRightToUse"
  ),
)

const InstructionRenewAndMaintainDiplomas = React.lazy(() =>
  import("src/pages/ANONYMOUS/Instructions/16_17.RenewAndMaintainDiplomas"),
)
function LazyLoadingComponent({ children }) {
  return (
    <React.Suspense
      fallback={
        <div className="loading-center" style={{ height: "100vh" }}>
          <Spin />
        </div>
      }
    >
      {children}
    </React.Suspense>
  )
}

const routes = [
  {
    path: ROUTER.SVG_VIEWER,
    element: (
      <LazyLoadingComponent>
        <SvgViewer />
      </LazyLoadingComponent>
    ),
  },

  {
    path: ROUTER.TEST_PRINT,
    element: (
      <LazyLoadingComponent>
        <TestPrint />
      </LazyLoadingComponent>
    ),
  },
  {
    path: ROUTER.PAYMENT_SUCCESS,
    element: (
      <LazyLoadingComponent>
        <PaymentSuccess />
      </LazyLoadingComponent>
    ),
  },
  // ADMIN
  {
    element: (
      <LazyLoadingComponent>
        <AminRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.QUAN_LY_PHAN_QUYEN,
        element: (
          <LazyLoadingComponent>
            <Role />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.LS_HOAT_DONG,
        element: (
          <LazyLoadingComponent>
            <HistoryActive />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.DANH_BA_TO_CHUC_DAI_DIEN,
        element: (
          <LazyLoadingComponent>
            <RepresentativeOrganizationsDirectory />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_BA_NGUOI_DAI_DIEN,
        element: (
          <LazyLoadingComponent>
            <Representative />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_BA_NHAN_VIEN,
        element: (
          <LazyLoadingComponent>
            <EmployeeContact />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_CHUC_VU,
        element: (
          <LazyLoadingComponent>
            <PositionManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DUYET_DANG_KY,
        element: (
          <LazyLoadingComponent>
            <RegistrationForm />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_BA_KHACH_HANG,
        element: (
          <LazyLoadingComponent>
            <CustomerDirector />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHAT_KY_HE_THONG,
        element: (
          <LazyLoadingComponent>
            <SystemHistory />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_MUC_HE_THONG,
        element: (
          <LazyLoadingComponent>
            <SystemCatePage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_MUC_HO_SO,
        element: (
          <LazyLoadingComponent>
            <DossierCatePage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HO_SO_TRUC_TIEP,
        element: (
          <LazyLoadingComponent>
            <FormDirect />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HO_SO_TRUC_TIEP_THU_PHI,
        element: (
          <LazyLoadingComponent>
            <PaymentManagementDirect />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_BIEN_LAI,
        element: (
          <LazyLoadingComponent>
            <ReceiptList tabID={2} />,
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.HO_SO_TRUC_TUYEN,
        element: (
          <LazyLoadingComponent>
            <ManagerListFormOnline />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HO_SO_TRUC_TUYEN_THU_PHI,
        element: (
          <LazyLoadingComponent>
            <ManagerListFormFee />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.KY_TRA_KET_QUA,
        element: (
          <LazyLoadingComponent>
            <SignResult />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.KY_TRA_VAN_BANG,
        element: (
          <LazyLoadingComponent>
            <SignVBBH />
          </LazyLoadingComponent>
        ),
      },
      // {
      //   path: ROUTER.CAP_SO_DON,
      //   element: (
      //     <LazyLoadingComponent>
      //       <ProvideNumberForm />
      //     </LazyLoadingComponent>
      //   ),
      // },
      {
        path: ROUTER.BAO_CAO_THU_PHI,
        element: (
          <LazyLoadingComponent>
            <ReportForm type={1} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.BAO_CAO_DANG_KY,
        element: (
          <LazyLoadingComponent>
            <ReportForm type={2} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.BAO_CAO_THU_PHI_2,
        element: (
          <LazyLoadingComponent>
            <ReportForm type={3} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THONG_KE,
        element: (
          <LazyLoadingComponent>
            <Statistic />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.YEU_CAU_HO_TRO,
        element: (
          <LazyLoadingComponent>
            <RequestSupport />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.YEU_CAU_CAP_NHAT,
        element: (
          <LazyLoadingComponent>
            <RequestUpdateInfo />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_QUY,
        element: (
          <LazyLoadingComponent>
            <FundManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_SO_DU,
        element: (
          <LazyLoadingComponent>
            <SurplusManager />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.XEM_DS_HS_IT,
        element: (
          <LazyLoadingComponent>
            <ListFormIT />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  //  USER
  {
    element: (
      <LazyLoadingComponent>
        <PrivateRoutes />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.DOI_MAT_KHAU,
        element: (
          <LazyLoadingComponent>
            <ChangePassword />
          </LazyLoadingComponent>
        ),
      },
      // {
      //   path: ROUTER.PRINT,
      //   element: (
      //     <LazyLoadingComponent>
      //       <PatentRegistrationDeclaration />
      //     </LazyLoadingComponent>
      //   ),
      // },
      {
        path: ROUTER.TO_KHAI_MOI,
        element: (
          <LazyLoadingComponent>
            <Declaration />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.THONG_TIN_TAI_KHOAN,
        element: (
          <LazyLoadingComponent>
            <PersonProfile />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_BA_NGUOI_DAI_DIEN_DV,
        element: (
          <LazyLoadingComponent>
            <AuthorizedPerson />
          </LazyLoadingComponent>
        ),
      },

      //Tờ khai
      {
        path: ROUTER.DANG_KY_TO_KHAI,
        element: (
          <LazyLoadingComponent>
            <ListDeclarationForm />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DANG_KY_SANG_CHE,
        element: (
          <LazyLoadingComponent>
            <PatentRegistration />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DANG_KY_GPHI,
        element: (
          <LazyLoadingComponent>
            <PatentRegistration />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TO_KHAI_DANG_KY_NHAN_HIEU,
        element: (
          <LazyLoadingComponent>
            <PatentRegistrationBrand />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TO_KHAI_CHUYEN_DOI_DANG_KY_QUOC_TE_NHAN_HIEU_THANH_DON_NOP_THEO_THE_THUC_QUOC_GIA,
        element: (
          <LazyLoadingComponent>
            <ConvertInternationalTrademarkRegistration />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_BAT_BUOC_CHUYEN_GIAO_QUYEN_SU_DUNG_SANG_CHE,
        element: (
          <LazyLoadingComponent>
            <ForcedTransfer />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TO_KHAI_DANG_KY_SANG_CHE_NGUON_GOC_VN,
        element: (
          <LazyLoadingComponent>
            <PatentRegistration />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DANG_KY_SANG_CHE_CHI_DINH_VN,
        element: (
          <LazyLoadingComponent>
            <PatentRegistration />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DANG_KY_SANG_CHE_CHON_VN,
        element: (
          <LazyLoadingComponent>
            <PatentRegistration />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO,
        element: (
          <LazyLoadingComponent>
            <RenewAndMaintainDiplomas isForm16={true} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_GIA_HAN_HIEU_LUC_VAN_BANG_BAO_HO,
        element: (
          <LazyLoadingComponent>
            <RenewAndMaintainDiplomas />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_XOA_TEN_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RequestDeletePresentative />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_XOA_TEN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RequestDeletePresentative isForm929={true} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_DEN_BU_DO_CHAM_CAP_PHEP_LUU_HANH_LAN_DAU_DOI_VOI_DUOC_PHAM_SAN_XUAT_THEO_BANG_DOC_QUYEN_SANG_CHE,
        element: (
          <LazyLoadingComponent>
            <CompensationForOwnerSHCN />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
        element: (
          <LazyLoadingComponent>
            <RenewPaternt />
          </LazyLoadingComponent>
        ),
      },
      // {
      //   path: ROUTER.TO_KHAI_DANG_KY_NHAN_HIEU,
      //   element: (
      //     <LazyLoadingComponent>
      //       <TrademarkRegistration />
      //     </LazyLoadingComponent>
      //   ),
      // },
      {
        path: ROUTER.TO_KHAI_DANG_KY_CHI_DAN_DIA_LY,
        element: (
          <LazyLoadingComponent>
            <GeographicalRegistration />{" "}
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN,
        element: (
          <LazyLoadingComponent>
            <RegistrationContractTransfer />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN,
        element: (
          <LazyLoadingComponent>
            <RegistrationTransferRightToUse />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DANG_KY_KIEU_DANG_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RegitrationIndustrial />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_SUA_DOI_BO_SUNG_DON_DANG_KY_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <ModifiedIndustialRegistration />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_NGUOI_NOP_DON_DANG_KY_DOI_TUONG_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RequestTransfer />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_VIEC_SUA_DOI_GIA_HAN_CHAM_DUT_HIEU_LUC_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RequestRemember />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_THU_TUC_GIAI_QUYET_KIEU_NAI_LIEN_QUAN_DEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <ComplaintForm />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_THU_TUC_DANG_KY_DU_KIEM_TRA_NGHIEP_VU_GIAM_DINH_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <AttendProfessionalExam />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_CAP_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <AssessorCard type={1} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_SUA_DOI_VAN_BANG_BAO_HO,
        element: (
          <LazyLoadingComponent>
            <EditProtectionText />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_CHAM_DUT_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
        element: (
          <LazyLoadingComponent>
            <TerminationDetails isForm14={false} />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TO_KHAI_YEU_CAU_CAP_LAI_THE_GIAM_DINH_VIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <AssessorCard type={2} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_CAP_GIAY_CHUNG_NHAN_TO_CHUC_DU_DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <CertificationEvaluateIndustrial isForm934={true} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_CAP_LAI_GIAY_CHUNG_NHAN_TO_CHUC_DU_DIEU_KIEN_HOAT_DONG_GIAM_DINH_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <CertificationEvaluateIndustrial isForm934={false} />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TO_KHAI_DANG_KY_THAM_DU_KIEM_TRA_NGIEP_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RegistrationIndustrialJoinTest />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TO_KHAI_YEU_CAU_CAP_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <IndustrialRepCertificateReq />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TO_KHAI_DANG_KY_DU_KIEM_TRA_NGHIEP_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            {/* <IndustrialRepCertificateReq /> */}
            <RegisterIndustrialIndustrial />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RequirementsRegisterName />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_NGUOI_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RecognitionOfIndustrialPropertyRepresentatives />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_TO_CHUC_DU_DIEU_KIEN_KINH_DOANH_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RecognizeSufficientBusinessConditions />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_GHI_NHAN_THAY_DOI_THONG_TIN_CUA_TO_CHUC_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RecordChangesInformation />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DE_NGHI_GIAO_QUYEN_DKSC_KDCN_TKBTLA_KET_QUA_CUA_NHIEM_VU_KHOA_HOC_VA_CONG_NGHE_SU_DUNG_NGAN_SACH_NHA_NUOC,
        element: (
          <LazyLoadingComponent>
            <ProposedDelegatingPower />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_CHO_PHEP_TO_CHUC_CA_NHAN_KHAC_SU_DUNG_SANG_CHE_KIEU_DANG_CONG_NGHIEP_THIET_KE_BO_TRI_LA_KET_QUA_CUA_NHIEM_VU_KHOA_HOC_VA_CONG_NGHE_SU_DUNG_NGAN_SACH_NHA_NUOC,
        element: (
          <LazyLoadingComponent>
            <AllowOthersUseDegree />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_YEU_CAU_THAM_DINH_NOI_DUNG_DON_DANG_KY_SANG_CHE,
        element: (
          <LazyLoadingComponent>
            <AppraisalPatentApplications />
          </LazyLoadingComponent>
        ),
      },

      // {
      //   path: ROUTER.TO_KHAI_YEU_CAU_CUNG_CAP_BAN_SAO_TAI_LIEU_THONG_TIN_SO_HUU_CONG_NGHIEP,
      //   element: (
      //     <LazyLoadingComponent>
      //       <RequirementsReissueCertificateProtection />
      //     </LazyLoadingComponent>
      //   ),
      // },

      {
        path: ROUTER.TO_KHAI_YEU_CAU_CAP_LAI_CHUNG_CHI_HANH_NGHE_DICH_VU_DAI_DIEN_SO_HUU_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <RenewIndustrialRepCertificate />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TO_KHAI_HUY_BO_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
        element: (
          <LazyLoadingComponent>
            <TerminationDetails isForm14={true} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_HUY_BO_HIEU_LUC_VAN_BANG_BAO_HO_SHCN,
        element: (
          <LazyLoadingComponent>
            <TerminationDetails isForm14={true} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUAN_LY_TAI_KHOAN_HO_TRO,
        element: (
          <LazyLoadingComponent>
            <Support />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.LS_HOAT_DONG_USER,
        element: (
          <LazyLoadingComponent>
            <ActivityLog />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HO_SO_CHO_XU_LY,
        element: (
          <LazyLoadingComponent>
            <ListProfile Type={1} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HO_SO_DANG_XU_LY,
        element: (
          <LazyLoadingComponent>
            <ListProfile Type={2} />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.NHOM_THANH_TOAN,
        element: (
          <LazyLoadingComponent>
            <GroupPayment />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_VAN_BANG,
        element: (
          <LazyLoadingComponent>
            <ListDiploma />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.CAU_HINH_CHU_KY,
        element: (
          <LazyLoadingComponent>
            <ConfigSignature />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANH_SACH_YEU_CAU_HO_TRO,
        element: (
          <LazyLoadingComponent>
            <ListRequestSupport />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_SU_DUNG,
        element: (
          <LazyLoadingComponent>
            <GuideUsing />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  //  ANONYMOUS
  {
    element: (
      <LazyLoadingComponent>
        <PublicRouters />
      </LazyLoadingComponent>
    ),
    children: [
      {
        path: ROUTER.HOME,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_NHAP,
        element: (
          <LazyLoadingComponent>
            <LoginPage />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUEN_MAT_KHAU,
        element: (
          <LazyLoadingComponent>
            <ForgotPassword />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.DANG_KY,
        element: (
          <LazyLoadingComponent>
            <RegisterPage />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.TRA_CUU_KET_QUA,
        element: (
          <LazyLoadingComponent>
            <SearchResult />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HO_TRO,
        element: (
          <LazyLoadingComponent>
            <HomeSupport />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.XAC_NHAN,
        element: (
          <LazyLoadingComponent>
            <Home />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.THU_TUC_HANH_CHINH,
        element: (
          <LazyLoadingComponent>
            <Procedure />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_THU_TUC_CAP_LAI_CAP_PHO_VAN_BANG_BAO_HO_SHCN,
        element: (
          <LazyLoadingComponent>
            <ProcedurePatentRegistration />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_SANG_CHE,
        element: (
          <LazyLoadingComponent>
            <InstructionRegistration />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_SANG_CHE_NGUON_GOC_VN,
        element: (
          <LazyLoadingComponent>
            <InstructionRegistrationOriginVN />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_SANG_CHE_CHI_DINH_VN,
        element: (
          <LazyLoadingComponent>
            <InstructionRegistrationAssignOriginVN />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_SANG_CHE_CHON_VN,
        element: (
          <LazyLoadingComponent>
            <InstructionRegistrationSelectedVN />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_KIEU_DANG_CONG_NGHIEP,
        element: (
          <LazyLoadingComponent>
            <InstructionRegistrationIndustrial />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_NHAN_HIEU,
        element: (
          <LazyLoadingComponent>
            <InstructionRegistrationBrand />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
        element: (
          <LazyLoadingComponent>
            <InstructionRegistrationBrandOriginVN />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_CHI_DAN_DIA_LY,
        element: (
          <LazyLoadingComponent>
            <InstructionRegistrationGeographical />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_THIET_KE_BO_TRI_MACH_BAN_DAN,
        element: (
          <LazyLoadingComponent>
            <PatentRegistrationSemiconductor />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DANG_KY_THIET_KE_BO_TRI_MACH_TICH_HOP_BAN_DAN,
        element: (
          <LazyLoadingComponent>
            <LayoutDesign />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DANG_KY_HOP_DONG_CHUYEN_NHUONG_QUYEN_SHCN,
        element: (
          <LazyLoadingComponent>
            <InstructionContractTransder />
          </LazyLoadingComponent>
        ),
      },

      {
        path: ROUTER.HUONG_DAN_DANG_KY_HOP_DONG_CHUYEN_QUYEN_SU_DUNG_DOI_TUONG_SHCN,
        element: (
          <LazyLoadingComponent>
            <InstructionTransferRightToUse />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.HUONG_DAN_DUY_TRI_HIEU_LUC_VAN_BANG_BAO_HO,
        element: (
          <LazyLoadingComponent>
            <InstructionRenewAndMaintainDiplomas />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.TO_KHAI_DANG_KY_QUOC_TE_NHAN_HIEU_CO_NGUON_GOC_VIET_NAM,
        element: (
          <LazyLoadingComponent>
            <TrademarkRegistrationVN />
          </LazyLoadingComponent>
        ),
      },
      {
        path: ROUTER.QUESTION,
        element: (
          <LazyLoadingComponent>
            <Question />
          </LazyLoadingComponent>
        ),
      },
    ],
  },
  {
    path: "*",
    element: (
      <LazyLoadingComponent>
        <NotFound />
      </LazyLoadingComponent>
    ),
  },
]
const AppRouter = () => {
  const renderRouter = useRoutes(routes)
  return renderRouter
}
export default AppRouter
