import { CloudUploadOutlined, DownOutlined } from "@ant-design/icons"
import { Collapse, FloatButton } from "antd"
import _ from "lodash"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { StoreContext } from "src/lib/store"
import Warning from "../Modal/Warning"
import UploadChunk from "./components/UploadChunk"
import { UploadChunkFilesStyle } from "./styles"
import { setLoadingUpload } from "src/redux/uploadFileList"
import { onFileChange } from "./functionsCommon"
import FileService from "src/services/FileService"
const UploadChunkFiles = () => {
  const dispatch = useDispatch()
  const { uploadListChunkFile } = useContext(StoreContext)
  const { listChunkFile, setistChunkFile } = uploadListChunkFile
  const {
    // loadingPercent,
    loadingUpload,
    dossierUploading,
  } = useSelector(state => state.uploadFileList)
  const [openPopup, setOpenPopup] = useState(false)
  const [collapse, setCollapse] = useState(false)
  const [listFileUploaded, setListFileUploaded] = useState([])
  const [openWarming, setOpenWarming] = useState(false)
  const [loading, setLoading] = useState(false)
  const [activeUpload, setActiveUpload] = useState(false)
  const [loadingPercent, setLoadingPercent] = useState({})
  const [fileUploaded, setFileUploaded] = useState({})

  const items = [
    {
      key: "1",
      label: (
        <div className="d-flex-sb" style={{ width: "100%" }}>
          <div>
            {!!true ? "Đang tải " : "Đã tải "}0{" / "}
            {listChunkFile?.length}
            {/* mục lên */}
          </div>
          <button
            className="ex-button pointer"
            onClick={() => setCollapse(pre => !pre)}
          >
            <DownOutlined />
          </button>
        </div>
      ),
      children: (
        <UploadChunk
          data={_.sortBy(listChunkFile, [
            o => {
              return loadingPercent?.[o?.ID]?.Percent === 100
            },
          ])}
        />
      ),

      showArrow: false,
    },
  ]
  useEffect(() => {
    //Kích hoạt bắt đầu đẩy file lên
    const listFileWillUpload = listChunkFile?.filter(
      item => !listFileUploaded?.includes(item?.ID),
    )
    if (listFileWillUpload?.length) {
      if (!openPopup) setOpenPopup(pre => true)
      if (listFileWillUpload[0]?.ID) {
        setActiveUpload(true)
      }
    }
  }, [listChunkFile])

  useEffect(() => {
    setActiveUpload(false)
    if (!!activeUpload) {
      //Danh sách file chưa upload và sẽ tải lên
      let listFileWillUpload = listChunkFile?.filter(item => {
        const checkInListFileUploaded = !listFileUploaded?.includes(item?.ID)
        //Khi up thành công hoặc thất bị thì loadingUpload đã được set
        //nhưng do listFileUploaded chưa ăn ngay luôn ID file đấy
        //nên phải check thêm ID file đã upload thành công hoặc thất bại đấy "loadingUpload?.ID"
        if (!!(loadingUpload?.IsError || loadingUpload?.IsSuccess))
          return checkInListFileUploaded && item?.ID !== loadingUpload?.ID
        else return checkInListFileUploaded
      })

      if (listFileWillUpload?.length) {
        let uploadFile = listFileWillUpload[0]
        setListFileUploaded(pre => [...pre, uploadFile?.ID])
        onFileChange({
          uploadFile: uploadFile,
          setFileUploaded,
        })
      }
    }
  }, [activeUpload])

  const uploadChunkingFile = async fileUploaded => {
    try {
      const res = await FileService.uploadChunkingFile({
        FileIDList: [
          {
            FileID: fileUploaded?.IDFileTus,
            FileName: fileUploaded?.name,
            NumberPage: fileUploaded?.NumberPage,
          },
        ],
        GuidID: fileUploaded?.ObjectID,
      })
      if (res?.isError) {
        setListFileUploaded(pre =>
          pre?.filter(item => item !== fileUploaded?.ID),
        )
        dispatch(
          setLoadingPercent({
            ID: fileUploaded?.ID,
            data: {
              DossierID: fileUploaded?.DossierID,
              TimeRemaining: 0,
              Percent: 100,
              IsSuccess: false,
              IsError: true,
              IsErrorUpFileApi: true,
              IsApi: "error",
            },
            noLoading: true,
          }),
        )
        return
      }
    } catch (error) {
      console.log("errrUploadChunkingFile", error)
    } finally {
    }
  }
  useEffect(() => {
    if (!!fileUploaded?.ID && fileUploaded?.ObjectID) {
      uploadChunkingFile(fileUploaded)
    }
  }, [fileUploaded])
  return !!openPopup ? (
    <UploadChunkFilesStyle>
      {!collapse ? (
        <Collapse
          items={items}
          expandIconPosition={"end"}
          defaultActiveKey={[1]}
          activeKey={collapse ? [] : [1]}
        />
      ) : (
        <FloatButton
          icon={<CloudUploadOutlined />}
          tooltip="Danh sách file tải lên"
          onClick={() => setCollapse(pre => !pre)}
        />
        // <button
        //   className="ex-button pointer"
        //   onClick={() => setCollapse(pre => !pre)}
        // >
        //   <DownloadOutlined />
        // </button>
      )}
      {!!openWarming && !!dossierUploading && (
        <Warning
          isOpen={!!openWarming}
          content={{
            title:
              "Các tài liệu đang được tải sẽ thất bại nếu tải lại trang, bạn có chắc vẫn muốn tải lại trang không?",
          }}
          onOk={() => {
            window.location.reload()
          }}
          onCancel={e => {
            setOpenWarming(false)
          }}
        />
      )}
    </UploadChunkFilesStyle>
  ) : (
    <></>
  )
}

export default UploadChunkFiles
