import moment from "moment"
import * as tus from "tus-js-client"

export const getChunkSize = SizeFile => {
  if (SizeFile < 1000000) {
    return parseInt(SizeFile, 10)
  } else return 1000000
}

export const uploadChunkFiles = ({ ListFile, setistChunkFile }) => {
  const IDFile = moment().format("YYYYMMDDHHmmss").toString()
  let newList = ListFile?.map((item, idx) => ({
    DossierID: item?.DossierID,
    ObjectID: item?.ObjectID,
    uid: item?.uid,
    lastModified: item?.lastModified,
    lastModifiedDate: item?.lastModifiedDate,
    name: item?.name,
    size: item?.size,
    type: item?.type,
    webkitRelativePath: item?.webkitRelativePath,
    file: item?.FileUpload || item,
    ID: item?.uid || `${IDFile}${idx}`,
    NumberPage: item?.FileUpload?.numPages || 0,
  }))
  newList = newList?.length ? newList : []
  setistChunkFile(pre => [...pre, ...newList])
}
export const uploadOneChunkFile = ({ file, ID, updateListFileAddFile }) => {
  const IDFile = ID || moment().format("YYYYMMDDHHmmss").toString()
  updateListFileAddFile({
    uid: file?.uid,
    lastModified: 1713940681724,
    lastModifiedDate: file?.lastModifiedDate,
    name: file?.name,
    size: file?.size,
    type: file?.type,
    webkitRelativePath: file?.webkitRelativePath,
    file: file,
    ID: file?.uid || IDFile,
  })
}
export const onFileChange = ({ uploadFile, setFileUploaded }) => {
  const { file, ID, ObjectID, DossierID } = uploadFile
  const { NumberPage } = file
  try {
    if (file) {
      const startTime = moment().format()
      const chunkSize = getChunkSize(file?.size)
      const API_TUS = "/uploadfile/files"
      // Do something before request is sent
      // Kiểm tra url truy cập của web để config tương ứng
      let BASE_URL = ""
      let IsDomain = 1
      const href = window.location.href
      if (href.includes("dichvucong.ipvietnam")) {
        BASE_URL =
          window.env?.REACT_APP_API_ROOT_MAIN ||
          process.env.REACT_APP_API_ROOT_MAIN
        IsDomain = 1
      } else if (href.includes("192.168.122.8")) {
        BASE_URL =
          window.env?.REACT_APP_API_ROOT_IP || process.env.REACT_APP_API_ROOT_IP
        IsDomain = 0
      } else {
        BASE_URL = window.env?.API_ROOT || process.env.REACT_APP_API_ROOT
        IsDomain = 1
      }
      // BASE_URL = window.env?.BASE_URL
      const upload = new tus.Upload(file, {
        endpoint: `${BASE_URL}${API_TUS}`,
        chunkSize: parseInt(chunkSize, 10),
        // chunkSize: parseInt(52428800, 10),
        // endpoint: `${BASE_URL}/uploadfile/files?FileObjectID=${FileObjectID}&&IsDomain=${IsDomain}`,
        // chunkSize: chunkSize,
        // Một mảng hoặc giá trị rỗng, cho biết số mili giây sẽ trôi qua trước khi lần tải lên tiếp theo bắt đầu sau khi quá trình truyền bị gián đoạn.
        // Độ dài của mảng cho biết số lần thử tối đa. Nếu lần thử cuối cùng không thành công, lỗi sẽ xuất hiện khi sử dụng onErrorlệnh gọi lại.
        retryDelays: [0, 1000, 3000, 5000],
        // Một đối tượng có các giá trị chuỗi được sử dụng làm siêu dữ liệu bổ sung
        // sẽ được chuyển đến máy chủ khi (và chỉ khi) tạo một bản tải lên mới.
        // Có thể được sử dụng cho tên tệp, loại tệp, v.v.,
        metadata: {
          filename: file?.name,
          filetype: file?.type,
          //ObjectID
        },

        onChunkComplete: function (
          chunkSize,
          bytesAccepted,
          bytesTotal, // bytesUploaded, bytesTotal)
        ) {},
        onSuccess: function () {},
        onError: function (error) {},
        onAfterResponse: function (req, res) {
          try {
            // var uploadedFile = res.getHeader("ca-uploaded-file")
            // var error = res.getHeader("ca-error")
            // if (uploadedFile) {
            //   //all ok?
            //   console.log(uploadedFile)
            // }
            // if (error) {
            //   //all ok?
            //   console.log(error)
            // }

            // console.log("response2", req)
            var url = req.getURL()
            if (
              url.split(`${API_TUS}/`)?.length > 1 &&
              !!url.split(`${API_TUS}/`)[1]
            ) {
              // console.log("url", url, url.split(`${API_TUS}/`)[1])
              setFileUploaded({
                DossierID: DossierID,
                uid: file?.uid,
                lastModified: file?.lastModified,
                lastModifiedDate: file?.lastModifiedDate,
                name: file?.name,
                size: file?.size,
                type: file?.type,
                webkitRelativePath: file?.webkitRelativePath,
                ObjectID: ObjectID,
                ID: ID,
                IDFileTus: url.split(`${API_TUS}/`)[1],
                NumberPage: NumberPage,
              })
            }
            // console.log(`Request for ${url} responded with ${res.getHeader()}`)
          } catch (error) {
            console.log("erroronAfterResponse", error)
          } finally {
          }
        },
      })

      // // Check if there are any previous uploads to continue.
      // upload.findPreviousUploads().then(function (previousUploads) {
      //   // Found previous uploads so we select the first one.
      //   if (previousUploads.length) {
      //     upload.resumeFromPreviousUpload(previousUploads[0])
      //   }
      // })
      // Start the upload
      upload.start()
    }
  } catch (error) {
    console.log("onFileChangeError", error)
  } finally {
  }
}
