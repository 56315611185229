import { uploadListLargFile } from "src/components/LoadingChunkFile/functionsCommon"
import Notice from "src/components/Notice"
import { checkTwoArr } from "src/lib/utils"
import ROUTER from "src/router"
import FileService from "src/services/FileService"
import { convertDocument, convertDocumentMultiLevel } from "./convertFunction"
import {
  addFormUploadFile,
  deleteFormUploadFile,
  reloadLoadingPercent,
} from "src/redux/uploadFileList"
//Thêm file và xóa file cho các tài liệu trong các form
export const handleUploadFileDocument = async ({
  responseDossier,
  listFileID,
  listFile,
  deleteFileID,
  uploadListFileStore,
  dispatch,
}) => {
  const [
    uploadFileList,
    updateListFileAddFile,
    deleteUploadFileList,
    updateListFileAddListFile,
    deleteListUploadFileList,
  ] = uploadListFileStore
  let listFileUpload = []
  for (let i = 0; i < listFileID?.length; i++) {
    let file = listFile?.filter(
      item =>
        (item?.DocumentID === listFileID[i]?.DocumentID ||
          item?.DocName === listFileID[i]?.DocName) &&
        !!item?.File?.uid,
    )
    // let formData = new FormData()
    if (file?.length) {
      // formData.append("GuidID", listFileID[i]?.ID)
      await file?.forEach(
        fileUpload =>
          listFileUpload.push({
            DossierID: responseDossier?.DossierID,
            ObjectID: listFileID[i]?.ID,
            // ...fileUpload?.File,
            uid: fileUpload?.File?.uid,
            lastModified: fileUpload?.File?.lastModified,
            lastModifiedDate: fileUpload?.File?.lastModifiedDate,
            name: fileUpload?.File?.name,
            size: fileUpload?.File?.size,
            type: fileUpload?.File?.type,
            webkitRelativePath: fileUpload?.File?.webkitRelativePath,
            FileUpload: fileUpload?.File,
            NumberPage: fileUpload?.numPages || 0,
          }),
        // formData.append("InsertFileList", fileUpload?.File)
      )
      // await FileService.uploadFileList(formData)
    }
  }
  // return
  if (listFileUpload?.length) {
    let ListLargFileUpload = listFileUpload?.filter(
      item =>
        !uploadFileList?.find(
          itemUploadFileList => itemUploadFileList?.uid === item?.uid,
        ),
    )

    if (ListLargFileUpload?.length)
      uploadListLargFile({
        ListFile: ListLargFileUpload,
        updateListFileAddListFile,
      })
    else {
      await dispatch(reloadLoadingPercent())
      dispatch(deleteFormUploadFile(responseDossier?.DossierID))
    }
  }
  //khi không có file up lên thì loadingPercent không đổi làm không chạy tiếp luồng được
  // nên cần gọi reloadLoadingPercent để luồng chạy tiếp
  else {
    await dispatch(reloadLoadingPercent())
    dispatch(deleteFormUploadFile(responseDossier?.DossierID))
  }
  //Xóa file doc
  if (deleteFileID?.length) {
    let formData = new FormData()
    deleteFileID?.forEach(imgID => formData.append("DeleteFileList", imgID))
    await FileService.uploadFileList(formData)
  }
}

//Bước của các form dùng để upload các file tài liệu đính kèm
export const validateStepUploadDocument = async ({
  bodyvali1,
  IsDraft,
  dossierLocal,
  formFile,
  dataDefaltDoc,
  dossierDetail,
  isEdit,
  checkbox,
  setDossierLocal,
  saveDossier,
  setActiveKey,
  isDocumentMultiLevel = false,
  nextStep = 3,
  notStepBigger3 = false,
  bodyAdd = {},
  dossierID,
  dispatch,
}) => {
  const dossier = !!IsDraft
    ? !!bodyvali1
      ? bodyvali1
      : dossierLocal
    : dossierLocal
  //Lưu tài liệu
  const values =
    IsDraft === true
      ? await formFile.getFieldValue()
      : await formFile.validateFields()

  dispatch(addFormUploadFile(dossierID?.DossierID))
  let Document = []
  if (!isDocumentMultiLevel)
    Document = await convertDocument(
      values,
      dataDefaltDoc,
      dossierDetail,
      isEdit,
      checkbox,
    )
  else
    Document = await convertDocumentMultiLevel(
      values,
      dataDefaltDoc,
      dossierDetail,
      isEdit,
      checkbox,
    )

  let arr1 = dossierDetail?.Document?.length
    ? dossierDetail?.Document?.map(item => item?.ID)
    : []
  let arr2 = Document?.filter(item => !!item?.ID)?.length
    ? Document?.filter(item => !!item?.ID)?.map(item => item?.ID)
    : []
  //Danh sách xóa document
  let deleteDoc = checkTwoArr(arr1, arr2)

  const body = {
    Document: Document,
    ListDocDelete: deleteDoc?.length ? deleteDoc : [],
    ...bodyAdd,
  }
  await setDossierLocal(pre => ({ ...pre, ...body }))
  if (!!IsDraft) {
    if (IsDraft === "save") {
      await saveDossier({ ...dossier, ...body }, false)
    } else {
      await saveDossier({ ...dossier, ...body }, IsDraft)
    }
  } else if (!!notStepBigger3) await saveDossier({ ...dossier, ...body }, false)
  else setActiveKey(nextStep)
}

//Xử lý form sau khi lưu hoặc lưu nháp
export const handleFormAffterSave = ({
  IsDraft,
  isEdit,
  onCancel,
  onOk,
  navigate,
  setDossierID,
  res,
}) => {
  if (!IsDraft) {
    //Component dùng cho cả modal và trang riêng
    //Nếu là modal thì tắt modal,
    // nếu là trang thì chuyển sang trang hồ sơ chờ xử lý
    if (isEdit) {
      onCancel && onCancel()
    } else navigate(ROUTER.HO_SO_CHO_XU_LY)
  }
  // if (IsDraft === true || IsDraft === "continue")
  //Lưu lại DosierID sau khi lưu nháp để tiếp tục xử lý đúng hồ sơ đã lưu nháp
  setDossierID(pre => res?.Object)
  if (IsDraft !== "continue") {
    onOk && onOk()
    Notice({
      isSuccess: true,
      msg: "Lưu thành công!",
    })
  }
}
