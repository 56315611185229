import React from "react"
import UploadChunkItem from "./UploadChunkItem"
import { Empty } from "antd"

const UploadChunk = ({ data }) => {
  return (
    <div className="ipload-chunk-item">
      {!!data?.length ? (
        data?.map((item, idx) => (
          <div
            key={idx}
            style={{ borderTop: idx === 0 ? "none" : "1px solid #e7e7e7" }}
          >
            <UploadChunkItem data={item} />
          </div>
        ))
      ) : (
        <Empty />
      )}
    </div>
  )
}

export default UploadChunk
